import React from 'react';
import PropTypes from 'prop-types';
import IconLogo from '../IconLogo/IconLogo';

const Logo = props => {
  return <IconLogo />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
