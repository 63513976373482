import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { capitalizeFirstLetter } from '../../helpers/dataDupe';

import {
  AspectRatioWrapper,
  IconReviewStar,
  InfoModal,
  NamedLink,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';
import { getWishlist } from '../../helpers/dataDupe';
import IconHeartFilled from '../IconHeartFilled/IconHeartFilled';
import IconHeart from '../IconHeart/IconHeart';
import { useDispatch } from 'react-redux';
import IconCurrentLocation from '../LocationAutocompleteInput/IconCurrentLocation';
import {
  EXPERTISE_OPTIONS,
  GROUPED_EXPERTISE,
  PATIENT_OPTIONS,
  SPECIALTIES_OPTIONS,
} from '../../config/customConfig';
import IconArrowRight from '../IconArrowRight/IconArrowRight';
import { isArrayLength, isLocaleGreek } from '../../util/genericHelpers';
import ListingDatesSection from './ListingDatesSection';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import moment from 'moment';
const MIN_LENGTH_FOR_LONG_WORDS = 10;

// Helper function to group events by date
const groupEventsByDate = events => {
  return events.reduce((acc, event) => {
    if (!acc[event.date]) {
      acc[event.date] = [];
    }
    acc[event.date].push(event);
    return acc;
  }, {});
};

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    viewport,
  } = props;

  const history = useHistory();
  const isGreek = isLocaleGreek();
  const routeConfiguration = useRouteConfiguration();
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, timeslots = [] } = currentListing.attributes || {};
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const expertise = publicData?.expertise || [];
  const totalReviews = currentListing?.attributes?.totalReviews || 0;
  const avgStarRating = currentListing?.attributes?.avgStarRating || 0;

  const groupedExpertise = GROUPED_EXPERTISE?.flatMap(g => g.options)
    .filter((v, i, a) => a?.findIndex(v2 => v2?.value === v?.value) === i)
    .map(l => {
      return {
        value: l.value,
        label: isGreek ? l.greekLabel : l.label,
        isExpertise: true,
      };
    });

  let specialityOption = PATIENT_OPTIONS.find(o => o.value === publicData?.speciality);

  const showTempSpeciality = id === '65832a02-5631-43f0-b5f5-0630b03413b1' && isGreek;
  //This is not a right approach but for time being we're doing this statically.

  if (showTempSpeciality && specialityOption?.value === 'doula-midwife') {
    specialityOption = {
      value: 'doula-midwife',
      label: 'Doula & Midwife',
      greekLabel: 'Μαιευτής',
    };
  }

  // Group events by date
  const groupedEvents = isArrayLength(timeslots) ? groupEventsByDate(timeslots) : [];

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.leftDetails}>
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
          {...setActivePropsMaybe}
        >
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />{' '}
          {/* <span className={css.favorite}>Available Now</span> */}{' '}
          <div className={css.head}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            <div className={css.price}>
              <div className={css.priceValue} title={priceTitle}>
                {isGreek ? specialityOption?.greekLabel : specialityOption?.label}
              </div>
            </div>
          </div>
        </AspectRatioWrapper>
        <div className={css.mainInfo}>
          <div className={css.reviewSec}>
            <span className={css.rating}>
              <IconReviewStar /> {avgStarRating}{' '}
            </span>
            <span className={css.totalreivews}>
              {totalReviews}{' '}
              {totalReviews > 1
                ? isGreek
                  ? 'Κριτικές'
                  : 'Reviews'
                : isGreek
                ? 'Κριτική'
                : 'Review'}
            </span>
          </div>
          {/* <div className={css.description}>gdfhdfhfgh egher ererr reryre</div> */}
          <div className={css.pillSec}>
            {isArrayLength(expertise)
              ? expertise.slice(0, 6).map((e, i) => {
                  const option =
                    isArrayLength(groupedExpertise) && groupedExpertise.find(o => o?.value === e);
                  return <span key={i}>{option?.label}</span>;
                })
              : null}
          </div>

          {Object.keys(groupedEvents).map(date => (
            <div key={date} className={css.timeSec}>
              <span className={css.date}>{moment(date).format('Do MMM')}</span>
              {groupedEvents[date].map(event => (
                <span>
                  {event?.startTime} - {event?.endTime}
                </span>
              ))}
            </div>
          ))}
          {/* <div className={css.otherInfo}>
            <div className={css.location}>
              <IconCurrentLocation /> <span>0.8</span> mi - New York, USA
            </div>
            <div className={css.location}>
              <IconCurrentLocation /> <span>10</span> years of experience
            </div>
          </div> */}
        </div>
      </div>
      {/* <ListingDatesSection
        listing={currentListing}
        intl={intl}
        pushToListingPage={() => {
          history.push(
            createResourceLocatorString('ListingPage', routeConfiguration, { id, slug }, {})
          );
        }}
      /> */}
      {/* 
      <div className={css.info}>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          {isBookingProcessAlias(publicData?.transactionProcessAlias) ? (
            <div className={css.perUnit}>
              <FormattedMessage
                id="ListingCard.perUnit"
                values={{ unitType: publicData?.unitType }}
              />
            </div>
          ) : null}
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div>
      </div> */}
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
