// Add here the translations of the country names using key "<language_code>: 'transalation'" e.g. fi: 'Afganistan'
// prettier-ignore
const countryCodes = [
  { code: 'AF', en: 'Afghanistan', fr: 'Afghanistan', es: 'Afganistán', de: 'Αφγανιστάν', el: 'Αφγανιστάν' },
  { code: 'AX', en: 'Åland Islands', fr: 'Îles Åland', es: 'Islas Áland', de: 'Åland', el: 'Ώλαντ' },
  { code: 'AL', en: 'Albania', fr: 'Albanie', es: 'Albania', de: 'Albanien', el: 'Αλβανία' },
  { code: 'DZ', en: 'Algeria', fr: 'Algérie', es: 'Argel', de: 'Αλγερία', el: 'Αλγερία' },
  { code: 'AS', en: 'American Samoa', fr: 'Samoa américaines', es: 'Samoa Americana', de: 'Αμερικανική Σαμόα', el: 'Αμερικανική Σαμόα' },
  { code: 'AD', en: 'Andorra', fr: 'Andorre', es: 'Andorra', de: 'Ανδόρα', el: 'Ανδόρα' },
  { code: 'AO', en: 'Angola', fr: 'Angola', es: 'Angola', de: 'Αγκόλα', el: 'Αγκόλα' },
  { code: 'AI', en: 'Anguilla', fr: 'Anguilla', es: 'Anguila', de: 'Ανγκουίλα', el: 'Ανγκουίλα' },
  { code: 'AQ', en: 'Antarctica', fr: 'Antarctique', es: 'Antártida', de: 'Ανταρκτική', el: 'Ανταρκτική' },
  { code: 'AG', en: 'Antigua and Barbuda', fr: 'Antigua-et-Barbuda', es: 'Antigua y Barbuda', de: 'Αντίγκουα και Μπαρμπούντα', el: 'Αντίγκουα και Μπαρμπούντα' },
  { code: 'AR', en: 'Argentina', fr: 'Argentine', es: 'Argentina', de: 'Αργεντινή', el: 'Αργεντινή' },
  { code: 'AM', en: 'Armenia', fr: 'Arménie', es: 'Armenia', de: 'Armenien', el: 'Αρμενία' },
  { code: 'AW', en: 'Aruba', fr: 'Aruba', es: 'Aruba', de: 'Αρούμπα', el: 'Αρούμπα' },
  { code: 'AU', en: 'Australia', fr: 'Australie', es: 'Australia', de: 'Αυστραλία', el: 'Αυστραλία' },
  { code: 'AT', en: 'Austria', fr: 'Autriche', es: 'Austria', de: 'Αυστρία', el: 'Αυστρία' },
  { code: 'AZ', en: 'Azerbaijan', fr: 'Azerbaïdjan', es: 'Azerbaiyán', de: 'Αζερμπαϊτζάν', el: 'Αζερμπαϊτζάν' },
  { code: 'BS', en: 'Bahamas', fr: 'Bahamas', es: 'Bahamas', de: 'Μπαχάμες', el: 'Μπαχάμες' },
  { code: 'BH', en: 'Bahrain', fr: 'Bahreïn', es: 'Bahréin', de: 'Μπαχρέιν', el: 'Μπαχρέιν' },
  { code: 'BD', en: 'Bangladesh', fr: 'Bangladesh', es: 'Bangladesh', de: 'Μπανγκλαντές', el: 'Μπανγκλαντές' },
  { code: 'BB', en: 'Barbados', fr: 'Barbade', es: 'Barbados', de: 'Μπαρμπάντος', el: 'Μπαρμπάντος' },
  { code: 'BY', en: 'Belarus', fr: 'Biélorussie', es: 'Belarús', de: 'Λευκορωσία', el: 'Λευκορωσία' },
  { code: 'BE', en: 'Belgium', fr: 'Belgique', es: 'Bélgica', de: 'Βέλγιο', el: 'Βέλγιο' },
  { code: 'BZ', en: 'Belize', fr: 'Belize', es: 'Belice', de: 'Belize', el: 'Μπελίζ' },
  { code: 'BJ', en: 'Benin', fr: 'Bénin', es: 'Benin', de: 'Μπενίν', el: 'Μπενίν' },
  { code: 'BM', en: 'Bermuda', fr: 'Bermudes', es: 'Bermudas', de: 'Βερμούδες', el: 'Βερμούδες' },
  { code: 'BT', en: 'Bhutan', fr: 'Bhoutan', es: 'Bhután', de: 'Μπουτάν', el: 'Μπουτάν' },
  { code: 'BO', en: 'Bolivia', fr: 'Bolivie', es: 'Bolivia', de: 'Bolivien', el: 'Βολιβία' },
  { code: 'BQ', en: 'Bonaire, Sint Eustatius and Saba', fr: 'Pays-Bas caribéens', es: 'Caribe Neerlandés', de: 'Bonaire, Sint Eustatius und Saba', el: 'Βοναίρ, Σιντ Εστάτιους και Σάμπα' },
  { code: 'BA', en: 'Bosnia and Herzegovina', fr: 'Bosnie-Herzégovine', es: 'Bosnia y Herzegovina', de: 'Βοσνία-Ερζεγοβίνη', el: 'Βοσνία-Ερζεγοβίνη' },
  { code: 'BW', en: 'Botswana', fr: 'Botswana', es: 'Botsuana', de: 'Μποτσουάνα', el: 'Μποτσουάνα' },
  { code: 'BV', en: 'Bouvet Island', fr: 'Île Bouvet', es: 'Isla Bouvet', de: 'Bouvetinsel', el: 'Νησί Μπουβέ' },
  { code: 'BR', en: 'Brazil', fr: 'Brésil', es: 'Brasil', de: 'Βραζιλία', el: 'Βραζιλία' },
  { code: 'IO', en: 'British Indian Ocean Territory', fr: 'Territoire britannique de l’Océan Indien', es: 'Territorio Británico del Océano Índico', de: 'Βρετανικό έδαφος του Ινδικού Ωκεανού', el: 'Βρετανικό Έδαφος του Ινδικού Ωκεανού' },
  { code: 'BN', en: 'Brunei Darussalam', fr: 'Brunei Darussalam', es: 'Brunéi', de: 'Μπρουνέι Νταρουσαλάμ', el: 'Μπρουνέι Δαρουσαλάμ' },
  { code: 'BG', en: 'Bulgaria', fr: 'Bulgarie', es: 'Bulgaria', de: 'Bulgarien', el: 'Βουλγαρία' },
  { code: 'BF', en: 'Burkina Faso', fr: 'Burkina Faso', es: 'Burkina Faso', de: 'Μπουρκίνα Φάσο', el: 'Μπουρκίνα Φάσο' },
  { code: 'BI', en: 'Burundi', fr: 'Burundi', es: 'Burundi', de: 'Μπουρούντι', el: 'Μπουρούντι' },
  { code: 'KH', en: 'Cambodia', fr: 'Cambodge', es: 'Camboya', de: 'Καμπότζη', el: 'Καμπότζη' },
  { code: 'CM', en: 'Cameroon', fr: 'Cameroun', es: 'Camerún', de: 'Καμερούν', el: 'Καμερούν' },
  { code: 'CA', en: 'Canada', fr: 'Canada', es: 'Canadá', de: 'Καναδάς', el: 'Καναδάς' },
  { code: 'CV', en: 'Cape Verde', fr: 'Cap-Vert', es: 'Cabo Verde', de: 'Πράσινο Ακρωτήριο', el: 'Πράσινο Ακρωτήριο' },
  { code: 'KY', en: 'Cayman Islands', fr: 'Iles Cayman', es: 'Islas Caimán', de: 'Νησιά Κέιμαν', el: 'Νησιά Κέιμαν' },
  { code: 'CF', en: 'Central African Republic', fr: 'République centrafricaine', es: 'República Centro-Africana', de: 'Κεντροαφρικανική Δημοκρατία', el: 'Κεντροαφρικανική Δημοκρατία' },
  { code: 'TD', en: 'Chad', fr: 'Tchad', es: 'Chad', de: 'Τσαντ', el: 'Τσαντ' },
  { code: 'CL', en: 'Chile', fr: 'Chili', es: 'Chile', de: 'Χιλή', el: 'Χιλή' },
  { code: 'CN', en: 'China', fr: 'Chine', es: 'China', de: 'Κίνα, Λαϊκή Δημοκρατία της', el: 'Κίνα, Λαϊκή Δημοκρατία της' },
  { code: 'CX', en: 'Christmas Island', fr: 'Île Christmas', es: 'Islas Christmas', de: 'Νησί των Χριστουγέννων', el: 'Νησί των Χριστουγέννων' },
  { code: 'CC', en: 'Cocos (Keeling) Islands', fr: 'Îles Cocos', es: 'Islas Cocos', de: 'Νήσοι Κόκος', el: 'Νήσοι Κόκος' },
  { code: 'CO', en: 'Colombia', fr: 'Colombie', es: 'Colombia', de: 'Κολομβία', el: 'Κολομβία' },
  { code: 'KM', en: 'Comoros', fr: 'Comores', es: 'Comoros', de: 'Κομόρες', el: 'Κομόρες' },
  { code: 'CG', en: 'Congo', fr: 'République du Congo', es: 'Congo', de: 'Κονγκό, Δημοκρατία του', el: 'Κονγκό, Δημοκρατία του' },
  { code: 'CD', en: 'Congo, the Democratic Republic of the', fr: 'République démocratique du Congo', es: 'República democrática del Congo', de: 'Κονγκό, Λαϊκή Δημοκρατία του', el: 'Κονγκό, Λαϊκή Δημοκρατία του' },
  { code: 'CK', en: 'Cook Islands', fr: 'Îles Cook', es: 'Islas Cook', de: 'Νήσοι Κουκ', el: 'Νήσοι Κουκ' },
  { code: 'CR', en: 'Costa Rica', fr: 'Costa Rica', es: 'Costa Rica', de: 'Κόστα Ρίκα', el: 'Κόστα Ρίκα' },
  { code: 'CI', en: 'Côte d\'Ivoire', fr: 'Côte d’Ivoire', es: 'Costa de Marfil', de: 'Ακτή Ελεφαντοστού', el: 'Ακτή Ελεφαντοστού' },
  { code: 'HR', en: 'Croatia', fr: 'Croatie', es: 'Croacia', de: 'Κροατία', el: 'Κροατία' },
  { code: 'CU', en: 'Cuba', fr: 'Cuba', es: 'Cuba', de: 'Κούβα', el: 'Κούβα' },
  { code: 'CW', en: 'Curaçao', fr: 'Curaçao', es: 'Curazao', de: 'Κουρασάο', el: 'Κουρασάο' },
  { code: 'CY', en: 'Cyprus', fr: 'Chypre', es: 'Chipre', de: 'Κύπρος', el: 'Κύπρος' },
  { code: 'CZ', en: 'Czech Republic', fr: 'République tchèque', es: 'República Checa', de: 'Τσεχική Δημοκρατία', el: 'Τσεχική Δημοκρατία' },
  { code: 'DK', en: 'Denmark', fr: 'Danemark', es: 'Dinamarca', de: 'Δανία', el: 'Δανία' },
  { code: 'DJ', en: 'Djibouti', fr: 'Djibouti', es: 'Yibuti', de: 'Τζιμπουτί', el: 'Τζιμπουτί' },
  { code: 'DM', en: 'Dominica', fr: 'Dominique', es: 'Domínica', de: 'Ντομίνικα', el: 'Ντομίνικα' },
  { code: 'DO', en: 'Dominican Republic', fr: 'République dominicaine', es: 'República Dominicana', de: 'Δομινικανή Δημοκρατία', el: 'Δομινικανή Δημοκρατία' },
  { code: 'EC', en: 'Ecuador', fr: 'Équateur', es: 'Ecuador', de: 'Εκουαδόρ', el: 'Εκουαδόρ' },
  { code: 'EG', en: 'Egypt', fr: 'Égypte', es: 'Egipto', de: 'Αίγυπτος', el: 'Αίγυπτος' },
  { code: 'SV', en: 'El Salvador', fr: 'Salvador', es: 'El Salvador', de: 'Ελ Σαλβαδόρ', el: 'Ελ Σαλβαδόρ' },
  { code: 'GQ', en: 'Equatorial Guinea', fr: 'Guinée équatoriale', es: 'Guinea Ecuatorial', de: 'Ισημερινή Γουινέα', el: 'Ισημερινή Γουινέα' },
  { code: 'ER', en: 'Eritrea', fr: 'Érythrée', es: 'Eritrea', de: 'Ερυθραία', el: 'Ερυθραία' },
  { code: 'EE', en: 'Estonia', fr: 'Estonie', es: 'Estonia', de: 'Εσθονία', el: 'Εσθονία' },
  { code: 'ET', en: 'Ethiopia', fr: 'Éthiopie', es: 'Etiopía', de: 'Αιθιοπία', el: 'Αιθιοπία' },
  { code: 'FK', en: 'Falkland Islands (Malvinas)', fr: 'Îles Falkland', es: 'Islas Malvinas', de: 'Νήσοι Φόκλαντ', el: 'Νήσοι Φόκλαντ' },
  { code: 'FO', en: 'Faroe Islands', fr: 'Îles Féroé', es: 'Islas Faroe', de: 'Νήσοι Φερόε', el: 'Νήσοι Φερόε' },
  { code: 'FJ', en: 'Fiji', fr: 'Fidji', es: 'Fiji', de: 'Φίτζι', el: 'Φίτζι' },
  { code: 'FI', en: 'Finland', fr: 'Finlande', es: 'Finlandia', de: 'Φινλανδία', el: 'Φινλανδία' },
  { code: 'FR', en: 'France', fr: 'France', es: 'Francia', de: 'Γαλλία', el: 'Γαλλία' },
  { code: 'GF', en: 'French Guiana', fr: 'Guyane française', es: 'Guayana Francesa', de: 'Γαλλική Γουιάνα', el: 'Γαλλική Γουιάνα' },
  { code: 'PF', en: 'French Polynesia', fr: 'Polynésie française', es: 'Polinesia Francesa', de: 'Γαλλική Πολυνησία', el: 'Γαλλική Πολυνησία' },
  { code: 'TF', en: 'French Southern Territories', fr: 'Terres australes et antarctiques françaises', es: 'Territorios Australes Franceses', de: 'Γαλλικά Νότια και Ανταρκτικά Εδάφη', el: 'Γαλλικά Νότια και Ανταρκτικά Εδάφη' },
  { code: 'GA', en: 'Gabon', fr: 'Gabon', es: 'Gabón', de: 'Γκαμπόν', el: 'Γκαμπόν' },
  { code: 'GM', en: 'Gambia', fr: 'Gambie', es: 'Gambia', de: 'Γκάμπια', el: 'Γκάμπια' },
  { code: 'GE', en: 'Georgia', fr: 'Géorgie', es: 'Georgia', de: 'Γεωργία', el: 'Γεωργία' },
  { code: 'DE', en: 'Germany', fr: 'Allemagne', es: 'Alemania', de: 'Γερμανία', el: 'Γερμανία' },
  { code: 'GH', en: 'Ghana', fr: 'Ghana', es: 'Ghana', de: 'Γκάνα', el: 'Γκάνα' },
  { code: 'GI', en: 'Gibraltar', fr: 'Gibraltar', es: 'Gibraltar', de: 'Γιβραλτάρ', el: 'Γιβραλτάρ' },
  { code: 'GR', en: 'Greece', fr: 'Grèce', es: 'Grecia', de: 'Ελλάδα', el: 'Ελλάδα' },
  { code: 'GL', en: 'Greenland', fr: 'Groenland', es: 'Groenlandia', de: 'Γροιλανδία', el: 'Γροιλανδία' },
  { code: 'GD', en: 'Grenada', fr: 'Grenade', es: 'Granada', de: 'Γρενάδα', el: 'Γρενάδα' },
  { code: 'GP', en: 'Guadeloupe', fr: 'Guadeloupe', es: 'Guadalupe', de: 'Γουαδελούπη', el: 'Γουαδελούπη' },
  { code: 'GU', en: 'Guam', fr: 'Guam', es: 'Guam', de: 'Γκουάμ', el: 'Γκουάμ' },
  { code: 'GT', en: 'Guatemala', fr: 'Guatemala', es: 'Guatemala', de: 'Γουατεμάλα', el: 'Γουατεμάλα' },
  { code: 'GG', en: 'Guernsey', fr: 'Guernesey', es: 'Guernsey', de: 'Guernsey' },
  { code: 'GN', en: 'Guinea', fr: 'Guinée', es: 'Guinea', de: 'Γουινέα', el: 'Γουινέα' },
  { code: 'GW', en: 'Guinea-Bissau', fr: 'Guinée-Bissau', es: 'Guinea-Bissau', de: 'Γουινέα-Μπισσάου', el: 'Γουινέα-Μπισσάου' },
  { code: 'GY', en: 'Guyana', fr: 'Guyane', es: 'Guayana', de: 'Γουιάνα', el: 'Γουιάνα' },
  { code: 'HT', en: 'Haiti', fr: 'Haïti', es: 'Haití', de: 'Αϊτή', el: 'Αϊτή' },
  { code: 'HM', en: 'Heard Island and McDonald Islands', fr: 'Îles Heard-et-MacDonald', es: 'Islas Heard y McDonald', de: 'Νησιά Heard και McDonald', el: 'Νησιά Heard και McDonald' },
  { code: 'VA', en: 'Holy See (Vatican City State)', fr: 'Saint-Siège (Vatican)', es: 'Ciudad del Vaticano', de: 'Πόλη του Βατικανού', el: 'Πόλη του Βατικανού' },
  { code: 'HN', en: 'Honduras', fr: 'Honduras', es: 'Honduras', de: 'Ονδούρα', el: 'Ονδούρα' },
  { code: 'HK', en: 'Hong Kong', fr: 'Hong Kong', es: 'Hong Kong', de: 'Χονγκ Κονγκ', el: 'Χονγκ Κονγκ' },
  { code: 'HU', en: 'Hungary', fr: 'Hongrie', es: 'Hungría', de: 'Ουγγαρία', el: 'Ουγγαρία' },
  { code: 'IS', en: 'Iceland', fr: 'Islande', es: 'Islandia', de: 'Ισλανδία', el: 'Ισλανδία' },
  { code: 'IN', en: 'India', fr: 'Inde', es: 'India', de: 'Ινδία', el: 'Ινδία' },
  { code: 'ID', en: 'Indonesia', fr: 'Indonésie', es: 'Indonesia', de: 'Ινδονησία', el: 'Ινδονησία' },
  { code: 'IR', en: 'Iran, Islamic Republic of', fr: 'Iran', es: 'Irán', de: 'Ιράν, Ισλαμική Δημοκρατία του', el: 'Ιράν, Ισλαμική Δημοκρατία του' },
  { code: 'IQ', en: 'Iraq', fr: 'Irak', es: 'Irak', de: 'Ιράκ', el: 'Ιράκ' },
  { code: 'IE', en: 'Ireland', fr: 'Irlande', es: 'Irlanda', de: 'Ιρλανδία', el: 'Ιρλανδία' },
  { code: 'IM', en: 'Isle of Man', fr: 'Ile de Man', es: 'Isla de Man', de: 'Νησί Man', el: 'Νησί Man' },
  { code: 'IL', en: 'Israel', fr: 'Israël', es: 'Israel', de: 'Ισραήλ', el: 'Ισραήλ' },
  { code: 'IT', en: 'Italy', fr: 'Italie', es: 'Italia', de: 'Ιταλία', el: 'Ιταλία' },
  { code: 'JM', en: 'Jamaica', fr: 'Jamaïque', es: 'Jamaica', de: 'Τζαμάικα', el: 'Τζαμάικα' },
  { code: 'JP', en: 'Japan', fr: 'Japon', es: 'Japón', de: 'Ιαπωνία', el: 'Ιαπωνία' },
  { code: 'JE', en: 'Jersey', fr: 'Jersey', es: 'Jersey', de: 'Τζέρσεϊ (Νησί της Μάγχης)', el: 'Τζέρσεϊ (Νησί της Μάγχης)' },
  { code: 'JO', en: 'Jordan', fr: 'Jordanie', es: 'Jordania', de: 'Τζόρνταν', el: 'Τζόρνταν' },
  { code: 'KZ', en: 'Kazakhstan', fr: 'Kazakhstan', es: 'Kazajstán', de: 'Καζακστάν', el: 'Καζακστάν' },
  { code: 'KE', en: 'Kenya', fr: 'Kenya', es: 'Kenia', de: 'Κένυα', el: 'Κένυα' },
  { code: 'KI', en: 'Kiribati', fr: 'Kiribati', es: 'Kiribati', de: 'Κιριμπάτι', el: 'Κιριμπάτι' },
  { code: 'KP', en: 'Korea, Democratic People\'s Republic of', fr: 'Corée du Nord', es: 'Corea del Norte', de: 'Κορέα, Λαϊκή Δημοκρατία της Κορέας (Βόρεια Κορέα)', el: 'Κορέα, Λαϊκή Δημοκρατία της Κορέας (Βόρεια Κορέα)' },
  { code: 'KR', en: 'Korea, Republic of', fr: 'Corée du Sud', es: 'Corea del Sur', de: 'Δημοκρατία της Κορέας (Νότια Κορέα)', el: 'Δημοκρατία της Κορέας (Νότια Κορέα)' },
  { code: 'KW', en: 'Kuwait', fr: 'Koweït', es: 'Kuwait', de: 'Κουβέιτ', el: 'Κουβέιτ' },
  { code: 'KG', en: 'Kyrgyzstan', fr: 'Kirghizistan', es: 'Kirguistán', de: 'Κιργιστάν', el: 'Κιργιστάν' },
  { code: 'LA', en: 'Laos', fr: 'Laos', es: 'Laos', de: 'Λάος', el: 'Λάος' },
  { code: 'LV', en: 'Latvia', fr: 'Lettonie', es: 'Letonia', de: 'Λετονία', el: 'Λετονία' },
  { code: 'LB', en: 'Lebanon', fr: 'Liban', es: 'Líbano', de: 'Λίβανος', el: 'Λίβανος' },
  { code: 'LS', en: 'Lesotho', fr: 'Lesotho', es: 'Lesotho', de: 'Λεσότο', el: 'Λεσότο' },
  { code: 'LR', en: 'Liberia', fr: 'Libéria', es: 'Liberia', de: 'Λιβερία', el: 'Λιβερία' },
  { code: 'LY', en: 'Libya', fr: 'Libye', es: 'Libia', de: 'Λιβύη', el: 'Λιβύη' },
  { code: 'LI', en: 'Liechtenstein', fr: 'Liechtenstein', es: 'Liechtenstein', de: 'Λιχτενστάιν', el: 'Λιχτενστάιν' },
  { code: 'LT', en: 'Lithuania', fr: 'Lituanie', es: 'Lituania', de: 'Λιθουανία', el: 'Λιθουανία' },
  { code: 'LU', en: 'Luxembourg', fr: 'Luxembourg', es: 'Luxemburgo', de: 'Λουξεμβούργο', el: 'Λουξεμβούργο' },
  { code: 'MO', en: 'Macao', fr: 'Macao', es: 'Macao', de: 'Μακάο', el: 'Μακάο' },
  { code: 'MK', en: 'North Macedonia', fr: 'Macédoine du Nord', es: 'Macedonia del Norte', de: 'Βόρεια Μακεδονία', el: 'Βόρεια Μακεδονία' },
  { code: 'MG', en: 'Madagascar', fr: 'Madagascar', es: 'Madagascar', de: 'Μαδαγασκάρη', el: 'Μαδαγασκάρη' },
  { code: 'MW', en: 'Malawi', fr: 'Malawi', es: 'Malawi', de: 'Μαλάουι', el: 'Μαλάουι' },
  { code: 'MY', en: 'Malaysia', fr: 'Malaisie', es: 'Malasia', de: 'Μαλαισία', el: 'Μαλαισία' },
  { code: 'MV', en: 'Maldives', fr: 'Maldives', es: 'Maldivas', de: 'Μαλδίβες', el: 'Μαλδίβες' },
  { code: 'ML', en: 'Mali', fr: 'Mali', es: 'Mali', de: 'Μάλι', el: 'Μάλι' },
  { code: 'MT', en: 'Malta', fr: 'Malte', es: 'Malta', de: 'Μάλτα', el: 'Μάλτα' },
  { code: 'MH', en: 'Marshall Islands', fr: 'Îles Marshall', es: 'Islas Marshall', de: 'Νήσοι Μάρσαλ', el: 'Νήσοι Μάρσαλ' },
  { code: 'MQ', en: 'Martinique', fr: 'Martinique', es: 'Martinica', de: 'Μαρτινίκα', el: 'Μαρτινίκα' },
  { code: 'MR', en: 'Mauritania', fr: 'Mauritanie', es: 'Mauritania', de: 'Μαυριτανία', el: 'Μαυριτανία' },
  { code: 'MU', en: 'Mauritius', fr: 'Maurice', es: 'Mauricio', de: 'Μαυρίκιος', el: 'Μαυρίκιος' },
  { code: 'YT', en: 'Mayotte', fr: 'Mayotte', es: 'Mayotte', de: 'Μαγιότ', el: 'Μαγιότ' },
  { code: 'MX', en: 'Mexico', fr: 'Mexique', es: 'México', de: 'Μεξικό', el: 'Μεξικό' },
  { code: 'FM', en: 'Micronesia, Federated States of', fr: 'Micronésie', es: 'Micronesia', de: 'Μικρονησία', el: 'Μικρονησία' },
  { code: 'MD', en: 'Moldova', fr: 'Moldavie', es: 'Moldova', de: 'Μολδαβία', el: 'Μολδαβία' },
  { code: 'MC', en: 'Monaco', fr: 'Monaco', es: 'Mónaco', de: 'Μονακό', el: 'Μονακό' },
  { code: 'MN', en: 'Mongolia', fr: 'Mongolie', es: 'Mongolia', de: 'Μογγολία', el: 'Μογγολία' },
  { code: 'ME', en: 'Montenegro', fr: 'Monténégro', es: 'Montenegro', de: 'Μαυροβούνιο', el: 'Μαυροβούνιο' },
  { code: 'MS', en: 'Montserrat', fr: 'Montserrat', es: 'Montserrat', de: 'Μονσεράτ', el: 'Μονσεράτ' },
  { code: 'MA', en: 'Morocco', fr: 'Maroc', es: 'Marruecos', de: 'Μαρόκο', el: 'Μαρόκο' },
  { code: 'MZ', en: 'Mozambique', fr: 'Mozambique', es: 'Mozambique', de: 'Μοζαμβίκη', el: 'Μοζαμβίκη' },
  { code: 'MM', en: 'Myanmar', fr: 'Myanmar', es: 'Myanmar', de: 'Μιανμάρ', el: 'Μιανμάρ' },
  { code: 'NA', en: 'Namibia', fr: 'Namibie', es: 'Namibia', de: 'Ναμίμπια', el: 'Ναμίμπια' },
  { code: 'NR', en: 'Nauru', fr: 'Nauru', es: 'Nauru', de: 'Ναουρού', el: 'Ναουρού' },
  { code: 'NP', en: 'Nepal', fr: 'Népal', es: 'Nepal', de: 'Νεπάλ', el: 'Νεπάλ' },
  { code: 'NL', en: 'Netherlands', fr: 'Pays-Bas', es: 'Países Bajos', de: 'Ολλανδία', el: 'Ολλανδία' },
  { code: 'NC', en: 'New Caledonia', fr: 'Nouvelle-Calédonie', es: 'Nueva Caledonia', de: 'Νέα Καληδονία', el: 'Νέα Καληδονία' },
  { code: 'NZ', en: 'New Zealand', fr: 'Nouvelle-Zélande', es: 'Nueva Zelanda', de: 'Νέα Ζηλανδία', el: 'Νέα Ζηλανδία' },
  { code: 'NI', en: 'Nicaragua', fr: 'Nicaragua', es: 'Nicaragua', de: 'Νικαράγουα', el: 'Νικαράγουα' },
  { code: 'NE', en: 'Niger', fr: 'Niger', es: 'Níger', de: 'Νίγηρας', el: 'Νίγηρας' },
  { code: 'NG', en: 'Nigeria', fr: 'Nigéria', es: 'Nigeria', de: 'Νιγηρία', el: 'Νιγηρία' },
  { code: 'NU', en: 'Niue', fr: 'Nioué', es: 'Niue', de: 'Νιούε', el: 'Νιούε' },
  { code: 'NF', en: 'Norfolk Island', fr: 'Île Norfolk', es: 'Isla Norfolk', de: 'Νησί Νόρφολκ', el: 'Νησί Νόρφολκ' },
  { code: 'MP', en: 'Northern Mariana Islands', fr: 'Îles Mariannes du Nord', es: 'Islas Marianas del Norte', de: 'Βόρειες Μαριάνες Νήσοι', el: 'Βόρειες Μαριάνες Νήσοι' },
  { code: 'NO', en: 'Norway', fr: 'Norvège', es: 'Noruega', de: 'Νορβηγία', el: 'Νορβηγία' },
  { code: 'OM', en: 'Oman', fr: 'Oman', es: 'Omán', de: 'Ομάν', el: 'Ομάν' },
  { code: 'PK', en: 'Pakistan', fr: 'Pakistan', es: 'Pakistán', de: 'Πακιστάν', el: 'Πακιστάν' },
  { code: 'PW', en: 'Palau', fr: 'Palaos', es: 'Palaos', de: 'Παλάου', el: 'Παλάου' },
  { code: 'PS', en: 'Palestine, State of', fr: 'Palestine', es: 'Palestina', de: 'Παλαιστίνη, Κράτος της', el: 'Παλαιστίνη, Κράτος της' },
  { code: 'PA', en: 'Panama', fr: 'Panama', es: 'Panamá', de: 'Παναμάς', el: 'Παναμάς' },
  { code: 'PG', en: 'Papua New Guinea', fr: 'Papouasie-Nouvelle-Guinée', es: 'Papúa Nueva Guinea', de: 'Παπούα Νέα Γουινέα', el: 'Παπούα Νέα Γουινέα' },
  { code: 'PY', en: 'Paraguay', fr: 'Paraguay', es: 'Paraguay', de: 'Παραγουάη', el: 'Παραγουάη' },
  { code: 'PE', en: 'Peru', fr: 'Pérou', es: 'Perú', de: 'Περού', el: 'Περού' },
  { code: 'PH', en: 'Philippines', fr: 'Philippines', es: 'Filipinas', de: 'Φιλιππίνες', el: 'Φιλιππίνες' },
  { code: 'PN', en: 'Pitcairn', fr: 'Îles Pitcairn', es: 'Islas Pitcairn', de: 'Νησιά Πίτκερν', el: 'Νησιά Πίτκερν' },
  { code: 'PL', en: 'Poland', fr: 'Pologne', es: 'Polonia', de: 'Πολωνία', el: 'Πολωνία' },
  { code: 'PT', en: 'Portugal', fr: 'Portugal', es: 'Portugal', de: 'Πορτογαλία', el: 'Πορτογαλία' },
  { code: 'PR', en: 'Puerto Rico', fr: 'Porto Rico', es: 'Puerto Rico', de: 'Πουέρτο Ρίκο', el: 'Πουέρτο Ρίκο' },
  { code: 'QA', en: 'Qatar', fr: 'Qatar', es: 'Catar', de: 'Κατάρ', el: 'Κατάρ' },
  { code: 'RE', en: 'Réunion', fr: 'Réunion', es: 'Reunión', de: 'Ρεϋνιόν', el: 'Ρεϋνιόν' },
  { code: 'RO', en: 'Romania', fr: 'Roumanie', es: 'Rumania', de: 'Ρουμανία', el: 'Ρουμανία' },
  { code: 'RU', en: 'Russian Federation', fr: 'Russie', es: 'Rusia', de: 'Ρωσία, Ρωσική Ομοσπονδία', el: 'Ρωσία, Ρωσική Ομοσπονδία' },
  { code: 'RW', en: 'Rwanda', fr: 'Rwanda', es: 'Ruanda', de: 'Ρουάντα', el: 'Ρουάντα' },
  { code: 'BL', en: 'Saint Barthélemy', fr: 'Saint-Barthélemy', es: 'San Bartolomé', de: 'Άγιος Βαρθολομαίος', el: 'Άγιος Βαρθολομαίος' },
  { code: 'SH', en: 'Saint Helena, Ascension and Tristan da Cunha', fr: 'Sainte-Hélène, Ascension et Tristan da Cunha', es: 'Santa Elena, Ascensión y Tristán de Acuña', de: 'Νήσοι Αγίας Ελένης, Αναβασίων και Τριστάν ντα Κούνια', el: 'Νήσοι Αγίας Ελένης, Αναβασίων και Τριστάν ντα Κούνια' },
  { code: 'KN', en: 'Saint Kitts and Nevis', fr: 'Saint-Christophe-et-Niévès', es: 'San Cristóbal y Nieves', de: 'Άγιος Χριστόφορος και Νέβις', el: 'Άγιος Χριστόφορος και Νέβις' },
  { code: 'LC', en: 'Saint Lucia', fr: 'Sainte-Lucie', es: 'Santa Lucía', de: 'Αγία Λουκία', el: 'Αγία Λουκία' },
  { code: 'MF', en: 'Saint Martin (French part)', fr: 'Saint-Martin (partie française)', es: 'San Martín (parte francesa)', de: 'Άγιος Μαρτίνος (Γαλλικό τμήμα)', el: 'Άγιος Μαρτίνος (Γαλλικό τμήμα)' },
  { code: 'PM', en: 'Saint Pierre and Miquelon', fr: 'Saint-Pierre-et-Miquelon', es: 'San Pedro y Miquelón', de: 'Άγιος Πέτρος και Μικελόν', el: 'Άγιος Πέτρος και Μικελόν' },
  { code: 'VC', en: 'Saint Vincent and the Grenadines', fr: 'Saint-Vincent-et-les Grenadines', es: 'San Vicente y las Granadinas', de: 'Άγιος Βικέντιος και Γρεναδίνες', el: 'Άγιος Βικέντιος και Γρεναδίνες' },
  { code: 'WS', en: 'Samoa', fr: 'Samoa', es: 'Samoa', de: 'Σαμόα', el: 'Σαμόα' },
  { code: 'SM', en: 'San Marino', fr: 'Saint-Marin', es: 'San Marino', de: 'Άγιος Μαρίνος', el: 'Άγιος Μαρίνος' },
  { code: 'ST', en: 'Sao Tome and Principe', fr: 'Sao Tomé-et-Principe', es: 'Santo Tomé y Príncipe', de: 'Σάο Τομέ και Πρίνσιπε', el: 'Σάο Τομέ και Πρίνσιπε' },
  { code: 'SA', en: 'Saudi Arabia', fr: 'Arabie Saoudite', es: 'Arabia Saudita', de: 'Σαουδική Αραβία', el: 'Σαουδική Αραβία' },
  { code: 'SN', en: 'Senegal', fr: 'Sénégal', es: 'Senegal', de: 'Σενεγάλη', el: 'Σενεγάλη' },
  { code: 'RS', en: 'Serbia', fr: 'Serbie', es: 'Serbia y Montenegro', de: 'Σερβία', el: 'Σερβία' },
  { code: 'SC', en: 'Seychelles', fr: 'Seychelles', es: 'Seychelles', de: 'Σεϋχέλλες', el: 'Σεϋχέλλες' },
  { code: 'SL', en: 'Sierra Leone', fr: 'Sierra Leone', es: 'Sierra Leona', de: 'Σιέρα Λεόνε', el: 'Σιέρα Λεόνε' },
  { code: 'SG', en: 'Singapore', fr: 'Singapour', es: 'Singapur', de: 'Σιγκαπούρη', el: 'Σιγκαπούρη' },
  { code: 'SX', en: 'Sint Maarten (Dutch part)', fr: 'Saint-Martin (partie néerlandaise)', es: 'San Martín (parte neerlandesa)', de: 'Άγιος Μαρτίνος (ολλανδικό μέρος)', el: 'Άγιος Μαρτίνος (ολλανδικό μέρος)' },
  { code: 'SK', en: 'Slovakia', fr: 'Slovaquie', es: 'Eslovaquia', de: 'Σλοβακία', el: 'Σλοβακία' },
  { code: 'SI', en: 'Slovenia', fr: 'Slovénie', es: 'Eslovenia', de: 'Σλοβενία', el: 'Σλοβενία' },
  { code: 'SB', en: 'Solomon Islands', fr: 'Îles Salomon', es: 'Islas Solomón', de: 'Νήσοι Σολομώντα', el: 'Νήσοι Σολομώντα' },
  { code: 'SO', en: 'Somalia', fr: 'Somalie', es: 'Somalia', de: 'Σομαλία', el: 'Σομαλία' },
  { code: 'ZA', en: 'South Africa', fr: 'Afrique du Sud', es: 'Sudáfrica', de: 'Νότια Αφρική', el: 'Νότια Αφρική' },
  { code: 'GS', en: 'South Georgia and the South Sandwich Islands', fr: 'Géorgie du Sud et les îles Sandwich du Sud', es: 'Georgia del Sur e Islas Sandwich de Sur', de: 'Νότια Γεωργία και Νότιοι Σάντουιτς', el: 'Νότια Γεωργία και Νότιοι Σάντουιτς' },
  { code: 'SS', en: 'South Sudan', fr: 'Sud-Soudan', es: 'Sudán del Sur', de: 'Νότιο Σουδάν', el: 'Νότιο Σουδάν' },
  { code: 'ES', en: 'Spain', fr: 'Espagne', es: 'España', de: 'Ισπανία', el: 'Ισπανία' },
  { code: 'LK', en: 'Sri Lanka', fr: 'Sri Lanka', es: 'Sri Lanka', de: 'Σρι Λάνκα', el: 'Σρι Λάνκα' },
  { code: 'SD', en: 'Sudan', fr: 'Soudan', es: 'Sudán', de: 'Σουδάν', el: 'Σουδάν' },
  { code: 'SR', en: 'Suriname', fr: 'Suriname', es: 'Surinam', de: 'Σουρινάμ', el: 'Σουρινάμ' },
  { code: 'SJ', en: 'Svalbard and Jan Mayen', fr: 'Svalbard et Jan Mayen', es: 'Islas Svalbard y Jan Mayen', de: 'Σβάλμπαρντ και Γιαν Μαγιέν', el: 'Σβάλμπαρντ και Γιαν Μαγιέν' },
  { code: 'SZ', en: 'Swaziland', fr: 'Eswatini', es: 'Suazilandia', de: 'Σουαζιλάνδη', el: 'Σουαζιλάνδη' },
  { code: 'SE', en: 'Sweden', fr: 'Suède', es: 'Suecia', de: 'Σουηδία', el: 'Σουηδία' },
  { code: 'CH', en: 'Switzerland', fr: 'Suisse', es: 'Suiza', de: 'Ελβετία', el: 'Ελβετία' },
  { code: 'SY', en: 'Syrian Arab Republic', fr: 'Syrie', es: 'Siria', de: 'Συρία, Αραβική Δημοκρατία', el: 'Συρία, Αραβική Δημοκρατία' },
  { code: 'TW', en: 'Taiwan', fr: 'Taiwan', es: 'Taiwán', de: 'Ταϊβάν', el: 'Ταϊβάν' },
  { code: 'TJ', en: 'Tajikistan', fr: 'Tadjikistan', es: 'Tayikistán', de: 'Τατζικιστάν', el: 'Τατζικιστάν' },
  { code: 'TZ', en: 'Tanzania', fr: 'Tanzanie', es: 'Tanzania', de: 'Τανζανία', el: 'Τανζανία' },
  { code: 'TH', en: 'Thailand', fr: 'Thaïlande', es: 'Tailandia', de: 'Ταϊλάνδη', el: 'Ταϊλάνδη' },
  { code: 'TL', en: 'Timor-Leste', fr: 'Timor-Leste', es: 'Timor-Leste', de: 'Τιμόρ-Λέστε', el: 'Τιμόρ-Λέστε' },
  { code: 'TG', en: 'Togo', fr: 'Togo', es: 'Togo', de: 'Τόγκο', el: 'Τόγκο' },
  { code: 'TK', en: 'Tokelau', fr: 'Tokelau', es: 'Tokelau', de: 'Τοκελάου', el: 'Τοκελάου' },
  { code: 'TO', en: 'Tonga', fr: 'Tonga', es: 'Tonga', de: 'Τόνγκα', el: 'Τόνγκα' },
  { code: 'TT', en: 'Trinidad and Tobago', fr: 'Trinité-et-Tobago', es: 'Trinidad y Tobago', de: 'Τρινιντάντ και Τομπάγκο', el: 'Τρινιντάντ και Τομπάγκο' },
  { code: 'TN', en: 'Tunisia', fr: 'Tunisie', es: 'Túnez', de: 'Τυνησία', el: 'Τυνησία' },
  { code: 'TR', en: 'Turkey', fr: 'Turquie', es: 'Turquía', de: 'Τουρκία', el: 'Τουρκία' },
  { code: 'TM', en: 'Turkmenistan', fr: 'Turkménistan', es: 'Turkmenistán', de: 'Τουρκμενιστάν', el: 'Τουρκμενιστάν' },
  { code: 'TC', en: 'Turks and Caicos Islands', fr: 'Îles Turques-et-Caïques', es: 'Islas Turcas y Caicos', de: 'Νήσοι Τερκς και Κάικος', el: 'Νήσοι Τερκς και Κάικος' },
  { code: 'TV', en: 'Tuvalu', fr: 'Tuvalu', es: 'Tuvalu', de: 'Τουβαλού', el: 'Τουβαλού' },
  { code: 'UG', en: 'Uganda', fr: 'Ouganda', es: 'Uganda', de: 'Ουγκάντα', el: 'Ουγκάντα' },
  { code: 'UA', en: 'Ukraine', fr: 'Ukraine', es: 'Ucrania', de: 'Ουκρανία', el: 'Ουκρανία' },
  { code: 'AE', en: 'United Arab Emirates', fr: 'Émirats Arabes Unis', es: 'Emiratos Árabes Unidos', de: 'Ηνωμένα Αραβικά Εμιράτα', el: 'Ηνωμένα Αραβικά Εμιράτα' },
  { code: 'GB', en: 'United Kingdom', fr: 'Royaume-Uni', es: 'Reino Unido', de: 'Ηνωμένο Βασίλειο', el: 'Ηνωμένο Βασίλειο' },
  { code: 'US', en: 'United States', fr: 'États-Unis', es: 'Estados Unidos de América', de: 'Ηνωμένες Πολιτείες Αμερικής', el: 'Ηνωμένες Πολιτείες Αμερικής' },
  { code: 'UM', en: 'United States Minor Outlying Islands', fr: 'Îles mineures éloignées des États-Unis', es: 'Islas Ultramarinas Menores de Estados Unidos', de: 'Μικρά Απομακρυσμένα Νησιά των Ηνωμένων Πολιτειών', el: 'Μικρά Απομακρυσμένα Νησιά των Ηνωμένων Πολιτειών' },
  { code: 'UY', en: 'Uruguay', fr: 'Uruguay', es: 'Uruguay', de: 'Ουρουγουάη', el: 'Ουρουγουάη' },
  { code: 'UZ', en: 'Uzbekistan', fr: 'Ouzbékistan', es: 'Uzbekistán', de: 'Ουζμπεκιστάν', el: 'Ουζμπεκιστάν' },
  { code: 'VU', en: 'Vanuatu', fr: 'Vanuatu', es: 'Vanuatu', de: 'Βανουάτου', el: 'Βανουάτου' },
  { code: 'VE', en: 'Venezuela', fr: 'Venezuela', es: 'Venezuela', de: 'Βενεζουέλα', el: 'Βενεζουέλα' },
  { code: 'VN', en: 'Viet Nam', fr: 'Viêt Nam', es: 'Vietnam', de: 'Βιετνάμ', el: 'Βιετνάμ' },
  { code: 'VG', en: 'Virgin Islands, British', fr: 'British Virgin Islands', es: 'Islas Vírgenes Británicas', de: 'Βρετανικές Παρθένοι Νήσοι', el: 'Βρετανικές Παρθένοι Νήσοι' },
  { code: 'VI', en: 'Virgin Islands, U.S.', fr: 'Îles Vierges américaines', es: 'Islas Vírgenes de los Estados Unidos de América', de: 'Αμερικανικές Παρθένοι Νήσοι', el: 'Αμερικανικές Παρθένοι Νήσοι' },
  { code: 'WF', en: 'Wallis and Futuna', fr: 'Wallis-et-Futuna', es: 'Wallis y Futuna', de: 'Γουάλις και Φουτούνα', el: 'Γουάλις και Φουτούνα' },
  { code: 'EH', en: 'Western Sahara', fr: 'Sahara occidental', es: 'Sahara Occidental', de: 'Δυτική Σαχάρα', el: 'Δυτική Σαχάρα' },
  { code: 'YE', en: 'Yemen', fr: 'Yémen', es: 'Yemen', de: 'Υεμένη', el: 'Υεμένη' },
  { code: 'ZM', en: 'Zambia', fr: 'Zambie', es: 'Zambia', de: 'Ζάμπια', el: 'Ζάμπια' },
  { code: 'ZW', en: 'Zimbabwe', fr: 'Zimbabwe', es: 'Zimbabue', de: 'Ζιμπάμπουε', el: 'Ζιμπάμπουε' }
];

const getCountryCodes = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.

  const updatedLang = lang === 'en' ? 'en' : 'el';
  const codes = countryCodes.map(c => {
    const countryName = c[updatedLang] ? c[updatedLang] : c['el'];
    const counryCode = c.code;

    return { code: counryCode, name: countryName };
  });
  return codes;
};

export default getCountryCodes;
