import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { getImageVariantInfo, imageIds } from '../EditListingPage/EditListingPage.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { sendSendGridNotification } from '../../util/api';

// ================ Action types ================ //

export const CREATE_LISTING_REQUEST = 'app/DoctorOnboardingPage/CREATE_LISTING_REQUEST';
export const CREATE_LISTING_SUCCESS = 'app/DoctorOnboardingPage/CREATE_LISTING_SUCCESS';
export const CREATE_LISTING_ERROR = 'app/DoctorOnboardingPage/CREATE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateInProgress: false,
  createListingError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_LISTING_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        createListingError: null,
      };
    case CREATE_LISTING_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case CREATE_LISTING_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        createListingError: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const createListingRequest = params => ({
  type: CREATE_LISTING_REQUEST,
  payload: { params },
});
export const createListingSuccess = result => ({
  type: CREATE_LISTING_SUCCESS,
  payload: result.data,
});
export const createListingError = error => ({
  type: CREATE_LISTING_ERROR,
  payload: error,
  error: true,
});

// ================ Thunk ================ //

export const sendNotification = params => {
  return async (dispatch, getState, sdk) => {
    try {
      const response = await sendSendGridNotification({ ...params });
      console.log(response, 'send-notification-response');
    } catch (error) {
      console.log(error, 'send-notification-failed');
    }
  };
};

export const createProviderListing = (data, config) => {
  return async (dispatch, getState, sdk) => {
    dispatch(createListingRequest());
    const { images, ...rest } = data;
    const imageProperty = typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    const ownListingValues = { ...imageProperty, ...rest };
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      expand: true,
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    let createListingResponse = null;
    return sdk.ownListings
      .create(ownListingValues, queryParams)
      .then(response => {
        createListingResponse = response;
        return dispatch(addMarketplaceEntities(response));
      })
      .then(() => {
        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingSuccess(createListingResponse));
        console.log(createListingResponse, '$$$$$$$createListingResponse');

        return createListingResponse;
      })
      .catch(e => {
        log.error(e, 'create-listing-failed', { listingData: data });
        return dispatch(createListingError(storableError(e)));
      });
  };
};

export function requestUpdateListing(data, config) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingRequest());
    const { id, stockUpdate, images, ...rest } = data;

    // If images should be saved, create array out of the image UUIDs for the API call
    const imageProperty = typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    const ownListingUpdateValues = { id, ...imageProperty, ...rest };
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      expand: true,
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    return sdk.ownListings
      .update(ownListingUpdateValues, queryParams)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(createListingSuccess(response));
        console.log(response, '$$$$$$$response');

        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-failed', { listingData: data });
        return dispatch(createListingError(storableError(e)));
      });
  };
}
