import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isArrayLength } from '../../helpers/dataDupe';
import { queryTransactionsData, queryUsersData, settleProvidePayout } from '../../util/api';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { reverse, sortBy } from 'lodash';

const RESULT_PAGE_SIZE = 100;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const SEARCH_USERS_REQUEST = 'app/DoctorsPaymentPage/SEARCH_USERS_REQUEST';
export const SEARCH_USERS_SUCCESS = 'app/DoctorsPaymentPage/SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_ERROR = 'app/DoctorsPaymentPage/SEARCH_USERS_ERROR';

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/DoctorsPaymentPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/DoctorsPaymentPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/DoctorsPaymentPage/FETCH_ORDERS_OR_SALES_ERROR';

export const SETTLE_PAYMENT_REQUEST = 'app/DoctorsPaymentPage/SETTLE_PAYMENT_REQUEST';
export const SETTLE_PAYMENT_SUCCESS = 'app/DoctorsPaymentPage/SETTLE_PAYMENT_SUCCESS';
export const SETTLE_PAYMENT_ERROR = 'app/DoctorsPaymentPage/SETTLE_PAYMENT_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  pagination: null,
  searchInProgress: false,
  fetchInProgress: false,
  settlePaymentInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  usersData: [],
  fetchOrdersOrSalesError: null,
  transactionRefs: [],
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };
    case SEARCH_USERS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        usersData: payload.data,
      };
    case SEARCH_USERS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };
    case SETTLE_PAYMENT_REQUEST:
      return {
        ...state,
        settlePaymentInProgress: true,
      };
    case SETTLE_PAYMENT_SUCCESS:
      return {
        ...state,
        settlePaymentInProgress: false,
      };
    case SETTLE_PAYMENT_ERROR:
      return { ...state, settlePaymentInProgress: false };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchUsersRequest = searchParams => ({
  type: SEARCH_USERS_REQUEST,
  payload: { searchParams },
});

export const searchUsersSuccess = response => ({
  type: SEARCH_USERS_SUCCESS,
  payload: { data: response.sellersData },
});

export const searchListingsError = e => ({
  type: SEARCH_USERS_ERROR,
  error: true,
  payload: e,
});

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const settlePaymentRequest = () => ({ type: SETTLE_PAYMENT_REQUEST });
const settlePaymentSuccess = response => ({
  type: SETTLE_PAYMENT_SUCCESS,
  payload: response,
});
const settlePaymentError = e => ({
  type: SETTLE_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const settlePayment = params => async (dispatch, getState, sdk) => {
  dispatch(settlePaymentRequest());
  const { txId } = params;
  try {
    const payout = await settleProvidePayout(params);
    dispatch(queryUsers());
    dispatch(fetchProviderTransactions({ providerId: txId }));
    dispatch(settlePaymentSuccess());
  } catch (error) {
    dispatch(settlePaymentError());
  }
};

export const fetchProviderTransactions = params => async (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());

  return queryTransactionsData(params)
    .then(response => {
      const { transactionsData = {} } = response || {};
      dispatch(addMarketplaceEntities(transactionsData));
      dispatch(fetchOrdersOrSalesSuccess(transactionsData));
      return transactionsData;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

export const queryUsers = () => async (dispatch, getState, sdk) => {
  dispatch(searchUsersRequest());
  try {
    const usersData = await queryUsersData({});
    dispatch(searchUsersSuccess(usersData));
    return usersData;
  } catch (e) {
    dispatch(searchListingsError(storableError(e)));
    throw e;
  }
};

export const loadData = (params, search, config) => {
  return queryUsers({});
};
