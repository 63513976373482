import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { isPartialPayment } from '../../util/dataExtractors';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  hasCommission,
  lineItemsTotal,
  nonCommissionNonReversalLineItems,
} from './LineItemSubTotalMaybe';
import { isArrayLength } from '../../util/genericHelpers';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemTotalPrice = props => {
  const { transaction, isProvider, code, marketplaceCurrency, userRole, lineItems, intl } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);
  const refund = lineItems.find(item => item.code === code && item.reversal);

  // Show subtotal only if commission line-item is applicable to user or refund is issued.
  const showSubTotal = hasCommission(lineItems, userRole) || refund;

  // all non-reversal, non-commission line items
  const subTotalLineItems = nonCommissionNonReversalLineItems(lineItems);
  const subTotalLineItemsActual =
    isArrayLength(subTotalLineItems) &&
    subTotalLineItems?.filter(s => s?.includeFor?.includes(userRole));

  const walletBalanceLineItem =
    isProvider &&
    isArrayLength(lineItems) &&
    lineItems.find(item => item.code === 'line-item/wallet-balance');

  // Calculate totalWalletBalance as absolute value of line item amount
  const totalWalletBalance = Math.abs(walletBalanceLineItem?.lineTotal?.amount);

  // Calculate subTotal from line items
  const subTotal = lineItemsTotal(subTotalLineItemsActual, marketplaceCurrency);

  // Access subTotal's amount and add it to totalWalletBalance
  const finalAmount = totalWalletBalance + (subTotal ? parseFloat(subTotal.amount) : 0);

  console.log(finalAmount, 'finalAmount');

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  const totalPrice = (isPartialPayment(transaction) && isProvider)
    ? new Money(finalAmount, 'EUR')
    : isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}></div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
