import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';

import appSettings from '../../config/settings';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { withViewport } from '../../util/uiHelpers';
import { LISTING_PAGE_DRAFT_VARIANT, createSlug, parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { LISTING_STATE_PUBLISHED, propTypes } from '../../util/types';
import {
  Button,
  ExternalLink,
  InlineTextButton,
  LimitedAccessBanner,
  LinkedLogo,
  Modal,
  ModalMissingInformation,
  NamedLink,
} from '../../components';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';

import css from './Topbar.module.css';
import LocaleMenu from '../LocaleMenu/LocaleMenu';
import { draftId, draftSlug } from '../../routing/routeConfiguration';
import { isIndividualUser, isUserEmailVerified } from '../../util/dataExtractors';

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationEmailSent: false,
    };
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { history, config, routeConfiguration } = this.props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history, routeConfiguration } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      config,
      isHeaderSticky,
      isFullWidthHeader,
      staticPageHeader,
      isModalClosedToday,
      handleDoItLater,
      ownListing,
      hideVerificationModal,
      showAvailabilityModal,
      dashboardHeader,
    } = this.props;

    const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

    const MAX_TAB_SCREEN_WIDTH = 1024;
    const MAX_MOBILE_SCREEN_WIDTH = 768;
    const isTabLayout = viewport.width < MAX_TAB_SCREEN_WIDTH;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';
    const isTabMenuOpen = isTabLayout && mobilemenu === 'open';
    const isTabSearchOpen = isTabLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        ownListing={ownListing}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
      />
    );

    const topbarSearcInitialValues = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords };
      }

      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds;
      return {
        location: locationFieldsPresent
          ? {
              search: address,
              selectedPlace: { address, origin, bounds },
            }
          : null,
      };
    };
    const initialSearchFormValues = topbarSearcInitialValues();

    const classes = classNames(rootClassName || css.root, className, {
      [css.staticPageTopbar]: staticPageHeader,
    });

    const isEditListing =
      typeof location?.pathname === 'string' && location?.pathname?.includes('/details');

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
            {notificationDot}
          </Button>
          <LinkedLogo format={'mobile'} alt={intl.formatMessage({ id: 'Topbar.logoIcon' })} />
          <LocaleMenu className={css.languageButton} />
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            ownListing={ownListing}
            onSearchSubmit={this.handleSubmit}
            appConfig={config}
            isHeaderSticky={isHeaderSticky}
            staticPageHeader={staticPageHeader}
            dashboardHeader={dashboardHeader}
            isFullWidthHeader={isFullWidthHeader}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={staticPageHeader ? isTabMenuOpen : isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
          ismobileMenuModal={true}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        {/* {!currentUser ||
        !ownListing?.attributes?.state === LISTING_PAGE_DRAFT_VARIANT ||
        isEditListing ? null : isIndividualUser(currentUser) || !isAuthenticated ? null : (
          <Modal
            id="EditListingWizard.noAvailability"
            isOpen={showAvailabilityModal ? false : 
              !isModalClosedToday && ownListing?.attributes?.state === LISTING_STATE_PUBLISHED
            }
            onClose={handleDoItLater}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
          >
            <div className={css.availabilityContainer}>
              <h4>
                <FormattedMessage id="Topbar.noAvailability" />
              </h4>
              <p className={css.availabilityContent}>
                <FormattedMessage id="Topbar.noAvailabilityContent" />
                <NamedLink
                  name="EditListingPage"
                  params={{
                    id: ownListing?.id?.uuid || draftId,
                    slug: createSlug(ownListing?.attributes?.title || draftSlug),
                    type: 'edit',
                    tab: 'details',
                  }}
                  to={{ state: { scroll: true } }}
                  className={css.navLink}
                >
                  <FormattedMessage id="Topbar.noAvailabilityContent2" />
                </NamedLink>
              </p>
            </div>
          </Modal>
        )} */}
        {!currentUser || hideVerificationModal
          ? null
          : !isUserEmailVerified(currentUser) &&
            isAuthenticated && (
              <Modal
                id="EditListingWizard.isUserEmailVerified"
                isOpen={!isUserEmailVerified(currentUser)}
                onClose={() => {}}
                onManageDisableScrolling={onManageDisableScrolling}
                usePortal
                hideCloseButton
              >
                <div className={css.verifiedEmailContainer}>
                  <h4>
                    <FormattedMessage id="Topbar.isUserEmailVerified" />
                  </h4>
                  <p className={css.verifiedEmailContent}>
                    <FormattedMessage id="Topbar.isUserEmailVerifiedContent" />
                    <br />
                    <div className={css.emailLogos}>
                      <ExternalLink href="https://mail.google.com">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACv0lEQVR4nO2UT2jTYByG48F5c4ieJ47RBE0rbZItcT2VHYQWWoebx11ksEPPehC92YA35wSvnpyDVoUetNrmYGvRKiK7bG6oW77uIqOCcyVx/eSbjWY1af70r/C98N7Kr++TPC1B4ODg4ODg6FLyeE7kKepugSTLeZLcyZNkseDxTBNdzk+JnlYluqjk6B0l5y2rOfoOzFDHrceT5GqBoqBBl0rDw4OdHg4zzKAi0Uuq5IX/NEevNIXYf/LG47WuFSmK6dR4JetlVMm7Zjj+b+fNAUhyywIAtQp4Lt7u8YDn4orkrVqMh0qOBuYAFFWzAQCBwEGZ5x59CQaPtTr86+joUSBwD9BNq/G/Abw102N2xmsAdYjPm+OjvNvxgGcCQOA+avfsAKC2DaBeBQjcFUgQh5yMLwvsLOC5qv5WrwAcKaVXBjS0ZYD5iZn3bgHsKNWoDHAB8Doz9sYUYEgEcHJuUc3RZ6tuAJopZaQMcADwI+vbvZUOVSKpGGwKgBq49g7eD8b2XAIcUKqZMsAmwPrzQPXy4zBE420BoJ66uQGvXrxeM/prtTOmDrGOavfzqsH49NNxeCEV/TPeNoDWybnFmnTGt+sGwGlV3fDvWZ8ipicODHcFoCmVYkNb3QLYfOHf1ivTMoCmVCIcX0FKdRLg5TP+U6MybQHQOjuz8AEIXKUDAJWFJ6HlZsPbAoBaFvwnAc++atd4WeDebvD+ETvj2wKAPltimMMyz4qywLWklMxz91bPjxxBN7sKoAWcY6KAZ7edP3X2mzzGXtLf6gkAilOlNGWIhvQMwIlSemX6CsBKKSNl+hLASCkzZfoWAAVpIgvsbVQzZfoawE0iGEDEb6ClRLBCCQD6/kecjMmmB4ZEWex3gHAqmjA9cPrG8sA+hMWb6AVAOBkDkWRMnHo4NdCp78fBwcHBIf67/ALECpwyy5gUBAAAAABJRU5ErkJggg==" />
                      </ExternalLink>
                      <ExternalLink href="https://outlook.live.com">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeklEQVR4nO3UMQqAMBBE0QUL7+F5LOxyxlSewTvZj/10Q8AI/gfbBpb8pAoAPklt08j4eft5aGSKBRo3IBJK8IgNv1CKhAwJpUjIkFCKhGYnBPzd0m+NjJ+na9XIFAt0bkAklOARG36hFAkZEkqRkCGhFAnNTggA6g0PsZwml5fu+o4AAAAASUVORK5CYII=" />
                      </ExternalLink>
                      <ExternalLink href="https://mail.yahoo.com">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVR4nO2YTWgTQRTHn98f+HUoRbAg+AV+oEI02Xkb2CIeFAUvRkGLhZq+SYJVBA/qpQexFy8qelNB8KC9KG3yXmLBHBS0WBDBi1BQsSAKIiLiZ61sD6Wd3SBp02RX8oNlDzvvP/OfnZ33ZgHq1Knz/6ORbxByv0buG70r+UBK9lRKnzC3TSt+Q8h5QrmplbBGvlIpfSDk1VrxN40yMnYpGezYxfOmqt0JnTNJyeMJ2ig/kvHcOqgkpLjL6GSEFJ+eqq62+aipqxWfh0pDkZ6FhPLaMPBFW70rJqvZEeUlhPLOMPA24xQXwXRAmDvofQtya7J6Wsllz+zbcqCyo/Z0yg8mdsp/2i12ytVJ2rKRFP8yvquHACMzpmfk4zrWyD+Njp8lEt2zytEhxUVD43c6xpuhGmjkS56lhJwuI/6wT/xFqBb+Hx9/JKen4V+xbXhvMaEMGYN/3+rcXQbVxG/7I5Sr/4ojlAtmXEpxG1QbNwFpJU/MdZzE7NZSMTp2fy0p+W5sxQOuFtSCdLwQIZRh4y08KrWTaGQx2g5TPBuDWkIo1z1LycofMttpxft9csg1qDUpVWjUSj4ZMzs0PpuetLoXEPIro83nzPbscggCWskJnzqpa+w58jmf2T8OQcFNYoTy3K+i9K9k+QVFBuZAkNC2NHvqGuQsIff61DvNEERI8R2vCbNUltsQVJJRbhotsUsb+JpR2ZUQZAjlbEkDtpyBoJPY0D1XK3nps3QGW53ifAgDKZXf67NtVuwnwLTT6RRnmwbKPS/UFDcLmwbcbAxhgZyeBs8SivQthbCQjHKTt+YvNEJYyFj5NaYB1xSEhZQqbDINpOPZVRAWyP3XaRqIZddDWCCb494snN8CYaHdkp0eA1Y+CmEh5ZeJbY5DWNA+59+Uyu2AsEDILaaBdju3G8ICoSR9yul9EBa0nT/mU40mICxoxac8BpBbIEwGCLnfPSOPnpOVPNVW7kitx1WnTh0IHn8BJ7HrZNGtN6MAAAAASUVORK5CYII=" />
                      </ExternalLink>
                    </div>
                  </p>
                  <InlineTextButton
                    rootClassName={css.helperLink}
                    inProgress={sendVerificationEmailInProgress}
                    ready={this.state.verificationEmailSent}
                    onClick={async () => {
                      this.setState({ verificationEmailSent: false });
                      const emailResponse = await onResendVerificationEmail();
                      this.setState({ verificationEmailSent: true });
                    }}
                  >
                    <FormattedMessage id="ModalMissingInformation.resendEmailLinkText" />
                  </InlineTextButton>
                </div>
              </Modal>
            )}
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={staticPageHeader ? isTabSearchOpen : isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
              appConfig={config}
            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p>
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedTopbar = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  return (
    <TopbarComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      {...props}
    />
  );
};

const Topbar = withViewport(EnhancedTopbar);
Topbar.displayName = 'Topbar';

export default Topbar;
