import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
  findNextBoundary,
} from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import moment from 'moment';
import { fetchAvailabilties } from '../../util/api';
import { appendAverageReviews, isArrayLength } from '../../util/genericHelpers';
import { fetchTimeSlots } from '../ListingPage/ListingPage.duck';
import { getCityCountry } from '../../util/dataExtractors';

function formatSlots(slots) {
  return slots.map(slot => {
    const { start, end } = slot.attributes;
    // Use moment to parse the start and end times
    const date = moment(start).format('YYYY-MM-DD'); // Formats to date as 'YYYY-MM-DD'
    const startTime = moment(start).format('HH:mm'); // Formats to time as 'HH:mm'
    const endTime = moment(end).format('HH:mm'); // Same as above for end time

    return {
      date,
      startTime,
      endTime,
    };
  });
}

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 30;

// ================ Action types ================ //

export const FETCH_FILTERS_SUCCESS = 'app/SearchPage/FETCH_FILTERS_SUCCESS';

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const removeNullAndDuplicates = array => {
  if (!Array.isArray(array) || !array.length) {
    return [];
  }

  const uniqueSet = new Set();

  return array.filter(item => {
    if (item === null || item === undefined) {
      return false;
    }

    if (typeof item === 'string' && uniqueSet.has(item)) {
      return false;
    }

    uniqueSet.add(item);
    return true;
  });
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case FETCH_FILTERS_SUCCESS:
      const countries = removeNullAndDuplicates(payload?.data?.country);
      const cities = removeNullAndDuplicates(payload?.data?.city);
      return {
        ...state,
        countryCityFilter: { countries, cities },
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchFiltersSuccess = response => ({
  type: FETCH_FILTERS_SUCCESS,
  payload: { data: response },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchWeekAvailability = params => async (dispatch, getState, sdk) => {
  try {
    console.log(params, 'params');
    const availabilities = await fetchAvailabilties({ ...params });
    const availability = availabilities?.availabilityResponse;
    return availability;
  } catch (error) {
    console.log(`Error fetching availability: ${error}`);
  }
};

export const searchListings = (searchParams, config) => async (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  // SearchPage can enforce listing query to only those listings with valid listingType
  // NOTE: this only works if you have set 'enum' type search schema to listing's public data fields
  //       - listingType
  //       Same setup could be expanded to 2 other extended data fields:
  //       - transactionProcessAlias
  //       - unitType
  //       ...and then turned enforceValidListingType config to true in configListing.js
  // Read More:
  // https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
  const searchValidListingTypes = listingTypes => {
    return config.listing.enforceValidListingType
      ? {
          pub_listingType: listingTypes.map(l => l.listingType),
          // pub_transactionProcessAlias: listingTypes.map(l => l.transactionType.alias),
          // pub_unitType: listingTypes.map(l => l.transactionType.unitType),
        }
      : {};
  };

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const searchTZ = 'Etc/UTC';
    const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';

    // SearchPage need to use a single time zone but listings can have different time zones
    // We need to expand/prolong the time window (start & end) to cover other time zones too.
    //
    // NOTE: you might want to consider changing UI so that
    //   1) location is always asked first before date range
    //   2) use some 3rd party service to convert location to time zone (IANA tz name)
    //   3) Make exact dates filtering against that specific time zone
    //   This setup would be better for dates filter,
    //   but it enforces a UX where location is always asked first and therefore configurability
    const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
    const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

    const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
        ? getExclusiveEndDate(endRaw, searchTZ)
        : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
    const day = 1440;
    const hour = 60;
    // When entire range is required to be available, we count minutes of included date range,
    // but there's a need to subtract one hour due to possibility of daylight saving time.
    // If partial range is needed, then we just make sure that the shortest time unit supported
    // is available within the range.
    // You might want to customize this to match with your time units (e.g. day: 1440 - 60)
    const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    return hasValidDates
      ? {
          start: getProlongedStart(startDate),
          end: getProlongedEnd(endDate),
          // Availability can be time-full or time-partial.
          // However, due to prolonged time window, we need to use time-partial.
          availability: 'time-partial',
          // minDuration uses minutes
          minDuration,
        }
      : {};
  };

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config.listing.listingTypes),
    perPage,
    pub_isPayoutEnabled: true,
  };

  if (params?.keywords?.includes('ψυχολογος')) {
    params.keywords = params.keywords.replaceAll('ψυχολογος', 'ψυχολόγος');
  }
  if (params?.pub_educationOrExperienceInUK && params?.pub_educationOrExperienceInUSA) {
    delete params.pub_educationOrExperienceInUK;
    delete params.pub_educationOrExperienceInUSA;
  }
  const isSpecialityModified =
    params?.pub_speciality === 'sex-coach' || params?.pub_speciality === 'child-psychologist';

  let queryPromise;

  if (isSpecialityModified) {
    // Modify params object for special cases
    const extraSpeciality =
      params?.pub_speciality === 'child-psychologist' ? 'child-psychologist' : 'sex-coach';
    const modifiedParams = {
      ...params,
      pub_expertise: undefined,
      pub_speciality: undefined,
      pub_extraSpeciality: extraSpeciality,
    };

    // Rerun the query with modified params
    queryPromise = sdk.listings.query(modifiedParams);
  } else {
    queryPromise = sdk.listings.query(params); // Original query
  }

  try {
    const response = await queryPromise;
    const listings = response?.data?.data;
    if (!listings) return response; // Early exit if no listings

    // Fetch reviews for all listings in parallel
    const reviewsResponses = await Promise.all(
      listings.map(listing =>
        sdk.reviews
          .query({ listingId: listing.id })
          .then(res => ({ success: true, data: res.data, listingId: listing.id }))
          .catch(error => ({ success: false, error, listingId: listing.id }))
      )
    );

    // Fetch timeslots only once per listing, assuming listingIds are unique
    const timeslotsResponses = await Promise.all(
      listings.map(listing =>
        sdk.timeslots
          .query({
            listingId: listing?.id?.uuid,
            start: moment()
              .startOf('day')
              .toISOString(),
            end: moment()
              .add(2, 'days')
              .startOf('day')
              .toISOString(),
          })
          .then(timeslots => ({ success: true, data: timeslots.data.data, listingId: listing.id }))
          .catch(error => ({ success: false, error, listingId: listing.id }))
      )
    );

    // Process listings with reviews and timeslots data
    const processedListings = listings.map(listing => {
      const reviewsData =
        reviewsResponses.find(r => r.listingId === listing.id && r.success)?.data?.data || [];
      const timeslotsData =
        timeslotsResponses.find(t => t.listingId === listing.id && t.success)?.data || [];

      const filteredReviews = reviewsData.filter(
        r => r.attributes.rating !== null && r.attributes.state === 'public'
      );

      const processedListing = {
        ...listing,
        attributes: {
          ...listing.attributes,
          timeslots: formatSlots(timeslotsData),
          reviews: filteredReviews,
          avgStarRating: appendAverageReviews(filteredReviews),
          totalReviews: filteredReviews.length,
        },
      };
      return processedListing;
    });

    // Dispatch updated listings
    dispatch(addMarketplaceEntities(response, { listingFields: config?.listing?.listingFields }));
    dispatch(searchListingsSuccess(response));
    dispatch(addMarketplaceEntities({ data: { data: processedListings } }));

    return response;
  } catch (e) {
    dispatch(searchListingsError(storableError(e)));
    throw e;
  }
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const loadData = (params, search, config) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return searchListings(
    {
      ...minStockMaybe,
      ...rest,
      ...originMaybe,
      page,
      pub_isStripeConnected: true,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.listing': [
        'title',
        'description',
        'geolocation',
        'price',
        'availabilityPlan',
        'publicData',
        'publicData.listingType',
        'publicData.transactionProcessAlias',
        'publicData.unitType',
        // These help rendering of 'purchase' listings,
        // when transitioning from search page to listing page
        'publicData.pickupEnabled',
        'publicData.shippingEnabled',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },
    config
  );
};
