import React, { useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  Logo,
  ExternalLink,
  NamedLink,
  Button,
} from '../../components';
import { Flip, toast } from 'react-toastify';
import IconSocialMediaLinkedin from '../IconSocialMediaLinkedin/IconSocialMediaLinkedin';
import { useDispatch } from 'react-redux';
import { subscribeToNewsletter } from './Footer.duck';
import { STATUS } from '../../helpers/enums';
import Icons from '../Icons/Icons';

import css from './Footer.module.css';
import { isLocaleGreek } from '../../util/genericHelpers';

const renderSocialMediaLinks = (intl, config) => {
  const { siteFacebookPage, siteInstagramPage, siteLinkedInPage, siteYoutubePage } = config;

  const links = [
    {
      key: 'linkToFacebook',
      href: siteFacebookPage,
      title: intl.formatMessage({ id: 'Footer.goToFacebook' }),
      icon: <IconSocialMediaFacebook />,
      show: true,
    },
    {
      key: 'linkToInstagram',
      href: siteInstagramPage,
      title: intl.formatMessage({ id: 'Footer.goToInstagram' }),
      icon: <IconSocialMediaInstagram className={css.instragram} />,
      show: true,
    },
    {
      key: 'linkToLinkedIn',
      href: siteLinkedInPage,
      title: intl.formatMessage({ id: 'Footer.goToLinkedIn' }),
      icon: <IconSocialMediaLinkedin className={css.linkedin} />,
      show: true,
    },
    {
      key: 'linkToYoutube',
      href: siteYoutubePage,
      title: intl.formatMessage({ id: 'Footer.goToYoutube' }),
      icon: <Icons name="youtubeIcon" />,
      show: true,
    },
  ];

  return links
    .filter(({ show }) => show)
    .map(({ key, href, title, icon }) => (
      <ExternalLink key={key} href={href} className={css.icon} title={title}>
        {icon}
      </ExternalLink>
    ));
};

const Footer = props => {
  const { rootClassName, className, intl, hideNewsLetter = false } = props;
  const config = useConfiguration();
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);

  const successMessage = intl.formatMessage({ id: 'Footer.successMessage' });
  const errorMessage = intl.formatMessage({ id: 'Footer.errorMessage' });

  const toasterProps = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Flip,
  };

  const onTriggerToast = type => {
    const [message, toastType] =
      type === STATUS.SUCCESS ? [successMessage, toast.success] : [errorMessage, toast.error];

    toastType(message, toasterProps);
  };

  const socialMediaLinks = renderSocialMediaLinks(intl, config);
  const classes = classNames(rootClassName || css.root, className);

  const [email, setEmail] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    setInProgress(true);
    try {
      const subscription = await dispatch(subscribeToNewsletter({ email }));
      onTriggerToast(subscription?.status);
      setEmail('');
    } catch {
      onTriggerToast(STATUS.ERROR);
    } finally {
      setInProgress(false);
    }
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const renderNewsletter = !hideNewsLetter ? (
    <div className={css.bottomSectionContent}>
      <div className={css.contentWidth}>
        <div className={css.newsletterSec}>
          <div className={css.joinNowcontent}>
            <h2>
              {' '}
              <FormattedMessage id="Footer.joinNewsletter" />
            </h2>
            <p>
              <FormattedMessage id="Footer.noSpam" />{' '}
            </p>
          </div>
          <div className={css.subscribeNow}>
            <div className={css.subscribe}>
              <form onSubmit={handleSubmit}>
                <div className={css.subscribeInput}>
                  {/* <label htmlFor="email">
                    <FormattedMessage id="Footer.emailAddress" />
                  </label> */}
                  <input
                    id="email"
                    type="email"
                    placeholder={isLocaleGreek() ? 'email' : 'Enter your email'}
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </div>
                <Button type="submit" inProgress={inProgress}>
                  <FormattedMessage id="Footer.subscribe" />
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div className={classes}>
      {renderNewsletter}
      <div className={css.topSectionContent}>
        <div className={css.contentWidth}>
          <div className={css.content}>
            <div className={css.leftSec}>
              <div className={css.links}>
                <NamedLink name="LandingPage" className={css.logoLink}>
                  <Logo format="desktop" className={css.logo} />
                </NamedLink>
                <div className={css.paymentAccepted}>
                  <Icons name="visa" />
                  <Icons name="mastercard" />
                </div>
                <p className={css.poweredby}>
                  <a href="https://stripe.com" target="_blank">
                    {' '}
                    <FormattedMessage id="Footer.securePayment" />
                  </a>
                </p>

                <p className={css.poweredby}>
                  <a href="mailto:info@vivianlab.com" target="_blank">
                    {' '}
                    <FormattedMessage id="Footer.email" />
                  </a>
                </p>
                <p className={css.copyright}>
                  <FormattedMessage id="Footer.copyright" />
                </p>
              </div>
            </div>
            <div className={css.rightSec}>
              <div className={css.links}>
                <h2>
                  <FormattedMessage id="Footer.help" />
                </h2>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="SearchPage" className={css.link}>
                      <FormattedMessage id="Footer.pricing" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="SearchPage" className={css.link}>
                      <FormattedMessage id="Footer.getCare" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="FAQPage" className={css.link}>
                      <FormattedMessage id="Footer.faq" />
                    </NamedLink>
                  </li>
                </ul>
              </div>

              <div className={css.links}>
                <h2>
                  <FormattedMessage id="Footer.register" />
                </h2>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="SignupPage" className={css.link}>
                      <FormattedMessage id="Footer.individual" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="SignupPage" className={css.link}>
                      <FormattedMessage id="Footer.doctorsAndProviders" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="CompaniesPage" className={css.link}>
                      <FormattedMessage id="Footer.companies" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="BlogPage" className={css.link}>
                      <FormattedMessage id="Footer.blog" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.links}>
                <h2>
                  {' '}
                  <FormattedMessage id="Footer.company" />
                </h2>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="AboutPage" className={css.link}>
                      <FormattedMessage id="Footer.toAboutPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="ContactPage" className={css.link}>
                      <FormattedMessage id="Footer.contactUs" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="PrivacyPolicyPage" className={css.link}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="TermsOfServicePage" className={css.link}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="CookiePolicy" className={css.link}>
                      <FormattedMessage id="Footer.cookiePolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.links}>
                <h2>
                  <FormattedMessage id="Footer.followUs" />
                </h2>
                {socialMediaLinks.length > 0 ? (
                  <div className={css.socialLinks}>{socialMediaLinks}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
