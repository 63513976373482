import { exchangeCode, getGoogleAccessToken } from '../../util/api';

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const parseUrlParams = url => {
  const params = {};
  const urlParams = new URLSearchParams(url);

  // Parse scopes
  const scopes = urlParams.get('scope');
  params.scopes = scopes ? scopes.split(' ') : [];

  // Parse authorization code
  params.code = urlParams.get('code');

  return params;
};

// ================ Action types ================ //

export const CREATE_TOKEN_REQUEST = 'app/GoogleAuthPage/CREATE_TOKEN_REQUEST';
export const CREATE_TOKEN_SUCCESS = 'app/GoogleAuthPage/CREATE_TOKEN_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  createTokenInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_TOKEN_REQUEST:
      return { ...state, createTokenInProgress: true };
    case CREATE_TOKEN_SUCCESS:
      return { ...state, createTokenInProgress: false };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const createTokenRequest = () => ({
  type: CREATE_TOKEN_REQUEST,
});

export const createTokenSuccess = () => ({
  type: CREATE_TOKEN_SUCCESS,
});

// ================ Thunk ================ //

export const googleAccessToken = () => async (dispatch, getState, sdk) => {
  dispatch(createTokenRequest());

  if (typeof window !== 'undefined') {
    const redirectURI = window.location.href;
    try {
      const link = await getGoogleAccessToken();
      link && window.open(link, '_self');
    } catch {
      window.open(redirectURI, '_self');
    }
  }
};

export const exchangeCodeAndSaveTokens = (code, state) => async (dispatch, getState, sdk) => {
  //   dispatch(createTokenRequest());
  if (typeof window !== 'undefined' && code) {
    const redirectURI = window.sessionStorage.getItem('redirectURI');
    const response = await exchangeCode({ code });
    if (response?.status && redirectURI) {
      window.open(redirectURI, '_self');
      window.sessionStorage.clear();
    }
  } else {
    return () => {};
  }
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const { code } = parseUrlParams(search);
  return dispatch(exchangeCodeAndSaveTokens(code));
};
