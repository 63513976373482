import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Field } from 'react-final-form';
import Icons from '../Icons/Icons';
import { ExternalLink, IconSpinner } from '..';

import css from './FileUploadField.module.css';

const FileUploadField = ({
  name,
  id,
  fieldLabel,
  objectURL,
  objectName,
  setObjectURL,
  setObjectName,
  hideLabel = false,
}) => {
  const isDev = typeof window !== 'undefined' && window.location?.hostname === 'localhost';
  const fileInputRef = useRef(null);
  const [inProgress, setInProgress] = useState(false);

  const uploadField = ({ input: { onChange, value }, label, ...rest }) => {
    const onFileChange = async event => {
      try {
        setInProgress(true);
        setObjectURL(null);
        setObjectName(null);
        const formData = new FormData();
        formData.append('image', event.target.files[0], event.target.files[0].name);

        const response = await axios.post(
          `${isDev ? 'http://localhost:3500' : ''}/api/upload-object`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        const { objectName, objectURL } = response.data || {};
        setInProgress(false);
        setObjectURL(objectURL);
        setObjectName(objectName);
        onChange(objectName);
      } catch (error) {
        setInProgress(false);
        setObjectURL(null);
        setObjectName(null);
        console.log(error);
      }
    };

    if (objectURL) {
      const handleDeleteFile = e => {
        e.stopPropagation();
        e.preventDefault();
        onChange(null);
        setObjectURL(null);
        setObjectName(null);
      };

      return (
        <div className={css.wrapper}>
          {/* <p className={css.label}>{fieldLabel}</p> */}
          <div className={css.fileIcon} title="download">
            <ExternalLink href={objectURL} className={css.fileLabel}>
              {objectName}
            </ExternalLink>
            <span onClick={handleDeleteFile}>
              <Icons name="deleteIcon" />
            </span>
          </div>
        </div>
      );
    }

    return objectURL ? null : (
      <div className={css.wrapper}>
        <div>
          <input
            type="file"
            // accept=".pdf,.docx"
            className={css.hiddenFileInput}
            onChange={onFileChange}
            ref={fileInputRef}
          />
          {!hideLabel && <label className={css.label}>{fieldLabel}</label>}
        </div>
        <div className={css.addFileButton} onClick={() => fileInputRef.current.click()}>
          {inProgress ? <IconSpinner showWhiteSpinner /> : <Icons name="upload" />}
        </div>
      </div>
    );
  };

  return <Field id={id} name={name} component={uploadField} />;
};

export default FileUploadField;
