import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconUser.module.css';

const IconUser = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_243_1091)">
        <circle cx="20" cy="20" r="19" fill="#FFF9F4" stroke="#835358" strokeWidth="2" />
        <g clipPath="url(#clip1_243_1091)">
          <path
            d="M20 20.0001C22.219 20.0001 24.0178 18.2012 24.0178 15.9822C24.0178 13.7632 22.219 11.9644 20 11.9644C17.781 11.9644 15.9821 13.7632 15.9821 15.9822C15.9821 18.2012 17.781 20.0001 20 20.0001Z"
            fill="#835358"
            stroke="#835358"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.1334 24.6205C28.8561 25.771 27.7756 28.0357 25.7041 28.0357H14.296C12.2244 28.0357 11.144 25.771 12.8667 24.6205C14.9079 23.2572 17.3612 22.4624 20 22.4624C22.6389 22.4624 25.0921 23.2572 27.1334 24.6205Z"
            fill="#835358"
            stroke="#835358"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_243_1091">
          <rect width="40" height="40" fill="white" />
        </clipPath>
        <clipPath id="clip1_243_1091">
          <rect width="18" height="18" fill="white" transform="translate(11 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconUser.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconUser.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconUser;
