import pick from 'lodash/pick';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import {
  createCheckoutSessionApi,
  createStripeSubscription,
  getStripeCheckoutSessionPayment,
  getSubscriptionProductPrice,
} from '../../util/api';
import { stripeCustomer } from '../PaymentMethodsPage/PaymentMethodsPage.duck';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/SubscriptionPage/SET_INITIAL_VALUES';
export const STRIPE_SUBSCRIPTION_PRODUCT_PRICE_REQUEST =
  'app/SubscriptionPage/STRIPE_SUBSCRIPTION_PRODUCT_PRICE_REQUEST';
export const STRIPE_SUBSCRIPTION_PRODUCT_PRICE_SUCCESS =
  'app/SubscriptionPage/STRIPE_SUBSCRIPTION_PRODUCT_PRICE_SUCCESS';
export const STRIPE_SUBSCRIPTION_PRODUCT_PRICE_ERROR =
  'app/SubscriptionPage/STRIPE_SUBSCRIPTION_PRODUCT_PRICE_ERROR';

export const CREATE_CHECKOUT_SESSION_LOADING =
  'app/SubscriptionPage/CREATE_CHECKOUT_SESSION_LOADING';
export const CREATE_CHECKOUT_SESSION_SUCCESS =
  'app/SubscriptionPage/CREATE_CHECKOUT_SESSION_SUCCESS';
export const CREATE_CHECKOUT_SESSION_ERROR = 'app/SubscriptionPage/CREATE_CHECKOUT_SESSION_ERROR';

// stripeCheckoutSessionTypes.js
export const FETCH_STRIPE_CHECKOUT_SESSION_REQUEST =
  'app/SubscriptionPage/FETCH_STRIPE_CHECKOUT_SESSION_REQUEST';
export const FETCH_STRIPE_CHECKOUT_SESSION_SUCCESS =
  'app/SubscriptionPage/FETCH_STRIPE_CHECKOUT_SESSION_SUCCESS';
export const FETCH_STRIPE_CHECKOUT_SESSION_FAILURE =
  'app/SubscriptionPage/FETCH_STRIPE_CHECKOUT_SESSION_FAILURE';

// ================ Reducer ================ //

const initialState = {
  fetchProductPriceInProgress: false,
  productPrices: [],
  fetchProductPriceError: false,
  isCreatingCheckoutSession: false,
  checkoutSessionData: null,
  checkoutSessionError: null,
  stripeSessionLoading: false,
  stripeSessionData: null,
  stripeSessionError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case STRIPE_SUBSCRIPTION_PRODUCT_PRICE_REQUEST:
      return { ...state, fetchProductPriceInProgress: true, fetchProductPriceError: null };
    case STRIPE_SUBSCRIPTION_PRODUCT_PRICE_SUCCESS:
      return {
        ...state,
        productPrices: payload,
        fetchProductPriceInProgress: false,
        fetchProductPriceError: null,
      };
    case STRIPE_SUBSCRIPTION_PRODUCT_PRICE_ERROR:
      return {
        ...state,
        fetchProductPriceInProgress: false,
        fetchProductPriceError: payload,
      };
    case CREATE_CHECKOUT_SESSION_LOADING:
      return {
        ...state,
        isCreatingCheckoutSession: true,
        checkoutSessionError: null,
      };
    case CREATE_CHECKOUT_SESSION_SUCCESS:
      return {
        ...state,
        isCreatingCheckoutSession: false,
        checkoutSessionData: action.payload,
      };
    case CREATE_CHECKOUT_SESSION_ERROR:
      return {
        ...state,
        isCreatingCheckoutSession: false,
        checkoutSessionError: action.payload,
      };
    case FETCH_STRIPE_CHECKOUT_SESSION_REQUEST:
      return {
        ...state,
        stripeSessionLoading: true,
        stripeSessionError: null,
      };
    case FETCH_STRIPE_CHECKOUT_SESSION_SUCCESS:
      return {
        ...state,
        stripeSessionLoading: false,
        stripeSessionData: action.payload,
      };
    case FETCH_STRIPE_CHECKOUT_SESSION_FAILURE:
      return {
        ...state,
        stripeSessionLoading: false,
        stripeSessionError: action.payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //
export const subscriptionSelectors = state => {
  const {
    fetchProductPriceInProgress,
    productPrices,
    fetchProductPriceError,
  } = state.SubscriptionPage;
  return {
    fetchProductPriceInProgress,
    productPrices,
    fetchProductPriceError,
  };
};

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const getSubscriptionProductPriceRequest = () => ({
  type: STRIPE_SUBSCRIPTION_PRODUCT_PRICE_REQUEST,
});
export const getSubscriptionProductPriceSuccess = data => ({
  type: STRIPE_SUBSCRIPTION_PRODUCT_PRICE_SUCCESS,
  payload: data,
});
export const getSubscriptionProductPriceError = error => ({
  type: STRIPE_SUBSCRIPTION_PRODUCT_PRICE_ERROR,
  payload: error,
  error: true,
});

export const setCreateCheckoutSessionLoading = () => ({
  type: CREATE_CHECKOUT_SESSION_LOADING,
});

export const setCreateCheckoutSessionSuccess = checkoutSession => ({
  type: CREATE_CHECKOUT_SESSION_SUCCESS,
  payload: checkoutSession,
});

export const setCreateCheckoutSessionError = checkoutError => ({
  type: CREATE_CHECKOUT_SESSION_ERROR,
  payload: checkoutError,
});

export const initiateStripeCheckoutSessionFetch = () => ({
  type: FETCH_STRIPE_CHECKOUT_SESSION_REQUEST,
});

export const stripeCheckoutSessionFetchSuccess = sessionData => ({
  type: FETCH_STRIPE_CHECKOUT_SESSION_SUCCESS,
  payload: sessionData,
});

export const stripeCheckoutSessionFetchFailure = error => ({
  type: FETCH_STRIPE_CHECKOUT_SESSION_FAILURE,
  payload: error,
});

// ================ Thunks ================ //

export const getSubscriptionPrices = () => async (dispatch, getState, sdk) => {
  dispatch(getSubscriptionProductPriceRequest());
  try {
    const response = await getSubscriptionProductPrice();
    dispatch(getSubscriptionProductPriceSuccess(response));
  } catch (e) {
    dispatch(getSubscriptionProductPriceError(storableError(e)));
  }
};

export const createSubscription = params => async (dispatch, getState, sdk) => {
  return await createStripeSubscription(params);
};

export const createCheckoutSession = body => async dispatch => {
  dispatch(setCreateCheckoutSessionLoading());
  try {
    const response = await createCheckoutSessionApi(body);
    dispatch(setCreateCheckoutSessionSuccess(response.data));
    return response;
  } catch (error) {
    dispatch(setCreateCheckoutSessionError(error.message));
  }
};

export const fetchStripeCheckoutSession = body => async dispatch => {
  dispatch(initiateStripeCheckoutSessionFetch());
  try {
    const response = await getStripeCheckoutSessionPayment(body);
    dispatch(stripeCheckoutSessionFetchSuccess(response));
    return response;
  } catch (error) {
    dispatch(stripeCheckoutSessionFetchFailure(error.message));
  }
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  return new Promise(async (resolve, reject) => {
    try {
      const currentUser = await dispatch(fetchCurrentUser());
      dispatch(setInitialValues());
      dispatch(getSubscriptionPrices());
      dispatch(stripeCustomer());
      resolve(currentUser);
    } catch (error) {
      reject(error);
    }
  });
};
