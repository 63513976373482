import React from 'react';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import Icons from '../Icons/Icons';
import { useHistory } from 'react-router-dom';

import css from './DashboardSidebar.module.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const SideLink = ({ iconName, linkText, onClick, isSelected }) => {
  return (
    <div
      className={classNames(css.sidelink, {
        [css.sideNavSelectedIcon]: isSelected,
      })}
      onClick={onClick}
    >
      <span className={classNames(css.sideNavIcon, css.shopIcon)}>
        <Icons name={iconName} />
      </span>{' '}
      {linkText}
    </div>
  );
};

function DashboardSidebar() {
  const history = useHistory();
  const location = useLocation();

  const redirectToPage = (name, params = {}) => {
    history.push(createResourceLocatorString(name, routeConfiguration(), params, {}));
  };

  return (
    <div>
      <div className={css.accordionMenu}>
        <SideLink
          iconName="dashboardHome"
          isSelected={location?.pathname === '/'}
          linkText="Home"
          onClick={() => redirectToPage('LandingPage')}
        />
        <SideLink
          iconName="analyticsIcons"
          linkText="Dashboard"
          isSelected={location?.pathname === '/dashboard'}
          onClick={() => redirectToPage('DashboardPage')}
        />
        <SideLink
          iconName="dashboardVouchers"
          linkText="Vouchers"
          isSelected={location?.pathname === '/vouchers'}
          onClick={() => redirectToPage('VouchersPage')}
        />
        <SideLink
          iconName="doctorsIcon"
          linkText="Doctors"
          isSelected={location?.pathname === '/doctors-payments'}
          onClick={() => redirectToPage('DoctorsPaymentPage')}
        />
      </div>
    </div>
  );
}

export default DashboardSidebar;
