import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, IconEmailAttention } from '..';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';

import css from './ModalMissingInformation.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectProfileUpdateInProgress,
  updateProfile,
} from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

const TermsReminder = props => {
  const { className } = props;

  const dispatch = useDispatch();
  const updateInProgress = useSelector(selectProfileUpdateInProgress);

  const termsLink = (
    <a
      className={css.termsLink}
      href={createResourceLocatorString('TermsOfServicePage', routeConfiguration(), {}, {})}
      target="_blank" // This attribute opens the link in a new tab
      rel="noopener noreferrer" // Best practice for security
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsTermsLinkText" />
    </a>
  );

  return (
    <div className={className}>
      <IconEmailAttention className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.verifyTermsAndConditionTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage
          id="ModalMissingInformation.verifyTermsAndConditionDescription"
          values={{ termsLink }}
        />
      </p>
      <Button
        inProgress={updateInProgress}
        onClick={() => {
          dispatch(
            updateProfile({
              protectedData: {
                termsVersion: process.env.REACT_APP_TOS,
              },
            })
          );
        }}
        className={css.modalMessage}
      >
        <FormattedMessage id="ModalMissingInformation.accept" />
      </Button>
    </div>
  );
};

export default TermsReminder;
