import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FormattedMessage } from 'react-intl';
import css from './SupportWidget.module.css';
import Icons from '../Icons/Icons';

const SupportWidget = () => {
  const [isOpen, setIsOpen] = useState(false);

  function onClick() {
    setIsOpen(!isOpen);
  }

  // close on escape
  useEffect(() => {
    const handleEscape = e => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };
    window.addEventListener('keydown', handleEscape);
    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, []);

  function onOutsideClick(e) {
    if (!e.target.closest(`.${css.supportWidgetFormWrapper}`)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onOutsideClick);
    } else {
      document.removeEventListener('click', onOutsideClick);
    }
    return () => {
      document.removeEventListener('click', onOutsideClick);
    };
  }, [isOpen]);

  return (
    <>
      <div className={css.supportWidgetWrapper} onClick={onClick}>
        <p className={css.helpText}>
          <FormattedMessage id="SupportWidget.buttonText" />
        </p>
      </div>
      <SupportWidgetForm isOpen={isOpen} />
    </>
  );
};

// should display a box on top of the widget with the email and phone number
function SupportWidgetForm({ isOpen }) {
  return (
    <div className={`${css.supportWidgetFormWrapper} ${isOpen ? css.open : css.closed}`}>
      <div className={css.supportWidgetFormHeader}>
        <div className={css.supportIcon}>
          <Icons name="heyhole" />
        </div>
        <h4>
          <FormattedMessage id="SupportWidget.buttonText" />
        </h4>
        <p>
          <FormattedMessage id="SupportWidget.helpMessage" />
        </p>
      </div>
      <div className={css.supportWidgetFormDetails}>
        <div className={css.supportFormRow}>
          <label htmlFor="email" className={css.supportWidgetFormItemLabel}>
            Email
          </label>
          <span className={css.supportWidgetFormItem}>info@vivianlab.com</span>
        </div>
        <div className={css.supportFormRow}>
          <label htmlFor="phone" className={css.supportWidgetFormItemLabel}>
            <FormattedMessage id="SupportWidget.phone" />
          </label>
          <span className={css.supportWidgetFormItem}>+30 21 1199 8304</span>
        </div>
      </div>
    </div>
  );
}

export default SupportWidget;
