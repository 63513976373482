import { subscribeMailChimpNewsLetter } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SEND_NEWSLETTER_REQUEST = 'app/PasswordChangePage/SEND_NEWSLETTER_REQUEST';
export const SEND_NEWSLETTER_SUCCESS = 'app/PasswordChangePage/SEND_NEWSLETTER_SUCCESS';
export const SEND_NEWSLETTER_ERROR = 'app/PasswordChangePage/SEND_NEWSLETTER_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendNewsletterError: null,
  sendNewsletterInProgress: false,
  passwordChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEND_NEWSLETTER_REQUEST:
      return {
        ...state,
        sendNewsletterInProgress: true,
        sendNewsletterError: null,
        passwordChanged: false,
      };
    case SEND_NEWSLETTER_SUCCESS:
      return { ...state, sendNewsletterInProgress: false, passwordChanged: true };
    case SEND_NEWSLETTER_ERROR:
      return { ...state, sendNewsletterInProgress: false, sendNewsletterError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const sendNewsletterRequest = () => ({ type: SEND_NEWSLETTER_REQUEST });
export const sendNewsletterSuccess = () => ({ type: SEND_NEWSLETTER_SUCCESS });
export const sendNewsletterError = error => ({
  type: SEND_NEWSLETTER_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const subscribeToNewsletter = params => async (dispatch, getState, sdk) => {
  const defaultLocale = (typeof window !== 'undefined' && localStorage.getItem('locale')) || 'de';
  dispatch(sendNewsletterRequest());
  try {
    const subscription = await subscribeMailChimpNewsLetter({ ...params, defaultLocale });
    dispatch(sendNewsletterSuccess());
    return subscription;
  } catch (error) {
    dispatch(sendNewsletterSuccess());
  }
};
