import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useIntl } from '../../util/reactIntl';
import { isArrayLength } from '../../util/genericHelpers';

import './ReactSelect.css';

const ReactSelect = ({
  options,
  isMulti,
  handleChange,
  locale,
  initialValues,
  customComponents,
  customStyle,
}) => {
  const intl = useIntl();
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (isArrayLength(initialValues)) {
      const formattedValues = options.filter(option => initialValues.includes(option.value));
      setSelectedValues(formattedValues);
    }
  }, [initialValues, options]);

  const handleSelectChange = selectedOption => {
    if (isMulti) {
      handleChange(selectedOption.map(option => option.value));
      setSelectedValues(selectedOption);
    } else {
      handleChange(selectedOption.value);
      setSelectedValues(selectedOption);
    }
  };

  const getSpecialtiesOptionsLabels = languageCode => {
    return (
      isArrayLength(options) &&
      options.map(option => {
        return {
          ...option,
          label: option.label,
        };
      })
    );
  };

  const placeholderText = intl.formatMessage({ id: 'ReactSelect.placeholder' });

  return (
    <Select
      className="custom-select"
      classNamePrefix="custom-select"
      placeholder={placeholderText}
      options={getSpecialtiesOptionsLabels(locale)}
      isMulti={isMulti}
      onChange={handleSelectChange}
      value={selectedValues}
      components={customComponents}
      styles={customStyle}
    />
  );
};

export default ReactSelect;
