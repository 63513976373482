import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSearch.module.css';

const IconSearch = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 11.6667C2.5 6.60405 6.60405 2.5 11.6667 2.5C16.7293 2.5 20.8333 6.60405 20.8333 11.6667C20.8333 16.7293 16.7293 20.8333 11.6667 20.8333C6.60405 20.8333 2.5 16.7293 2.5 11.6667ZM11.6667 0C5.22335 0 0 5.22335 0 11.6667C0 18.11 5.22335 23.3333 11.6667 23.3333C14.438 23.3333 16.9837 22.367 18.9852 20.753L24.5328 26.3005C25.021 26.7887 25.8123 26.7887 26.3005 26.3005C26.7887 25.8123 26.7887 25.021 26.3005 24.5328L20.753 18.9852C22.367 16.9837 23.3333 14.438 23.3333 11.6667C23.3333 5.22335 18.11 0 11.6667 0Z"
        fill="#8E8E8E"
      />
    </svg>
  );
};

IconSearch.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSearch.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSearch;
