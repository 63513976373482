import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import './DatePicker.css';
import './Calendar.css';

const BirthdayInput = ({ onDateChange }) => {
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const handleDateChange = newDate => {
    setDateOfBirth(newDate);
    onDateChange(newDate);
  };

  return (
    <DatePicker
      value={dateOfBirth}
      onChange={handleDateChange}
      format="dd/MM/yyyy"
      dayPlaceholder="dd"
      monthPlaceholder="mm"
      yearPlaceholder="yyyy"
      clearIcon={null} // Hide clear icon
    />
  );
};

export default BirthdayInput;
